import * as React from 'react';
import Box from '@material-ui/core/Box';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';

const StepperHeader = (props) => {
    var stepForm = props.stepForm
    var amountStep = props.amountStep ? props.amountStep : 0;
    var stepsDefault = []
    for (let i = 0; i < amountStep; i++) {
        stepsDefault.push('ส่วนที่ ' + (i + 1))
    }
    var steps = props.steps ? props.steps : stepsDefault
    return (
        <Box sx={{ width: '100%' }}>
            <Stepper activeStep={stepForm} alternativeLabel>
                {steps.map((label) => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
        </Box>
    );
}
export default StepperHeader
