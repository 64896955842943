import React, { lazy } from "react"
import {
    CAvatar,
    CContainer,
    CButton,
    CButtonGroup,
    CInputGroupText,
    CCard,
    CCardBody,
    CCardFooter,
    CCardHeader,
    CCol,
    CProgress,
    CRow,
    CTable,
    CTableBody,
    CTableDataCell,
    CTableHead,
    CTableHeaderCell,
    CTableRow,
    CModal,
    CModalHeader,
    CModalBody,
    CModalFooter,
    CModalTitle,
    CForm,
    CFormLabel,
    CFormInput,
    CFormTextarea,
    CFormCheck,
    CInputGroup,
    CFormSwitch,
    CSpinner
} from "@coreui/react"
//base on react select component
//other fetures pls read https://react-select.com/
import Select from "../../../containers/SelectOption";
//=================================================
import ButtonLoading from "../../../containers/ButtonLoading"
import StepperForm from "../../../containers/StepperForm"
import ImageUploading from "react-images-uploading";
import SnackbarAlert from "../../../containers/SnackbarAlert"
import AsteriskPoint from "../../../containers/AsteriskPoint";
import DateInput from "../../../containers/DateInput"
import TimeInput from "../../../containers/TimeInput"
import CIcon from "@coreui/icons-react"
import axios from "axios";
import { Component } from "react"
import {
    API_URL
} from "../../../settings"
import {
    getCurDate
} from "../../../lib/util"
import { cilStar } from '@coreui/icons';
import { Icon, IconButton } from "@material-ui/core";
import liff from '@line/liff';
import { ThemeConsumer } from "react-bootstrap/esm/ThemeProvider";

export class FeedbackBus extends Component {
    constructor(props) {
        super(props);
        this.state = {
            curDate: getCurDate(),
            isLoadded: false,
            name: '',
            userLineID: '',
            pictureUrl: '',
            starRating: 0,
            dateUsed: '',
            timeUsed: '',
            busUsed: '',
            feedbackMessage: '',
            isSetDatetimeUsed: false,
            isSummitedFeeback: false,
            optionsBusUsed: []
        }
    }

    componentDidMount = async () => {
        await liff.init({ liffId: '1657406254-Jrypq1z3' }).catch(err => { throw err });
        if (liff.isLoggedIn()) {
            let getProfile = await liff.getProfile();
            let getIDToken = await liff.getIDToken();
            console.log("---------userID-----------")
            console.log(getProfile.userId);
            console.log("---------getIDToken-----------")
            console.log(getIDToken);
            this.setState({
                name: getProfile.displayName,
                userLineID: getProfile.userId,
                pictureUrl: getProfile.pictureUrl,
            });
        } else {
            liff.login();
        }
        this.getBusTransport();
        this.getNearbyBus();
        this.setState({
            isLoadded: true
        })
    }

    getNearbyBus = () => {
        if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition((position) => {
                    console.log(position.coords.latitude)
                    console.log(position.coords.longitude)
                    let getData = async () => {
                        let results = await axios.get(API_URL + 'getBusNearby?cur_lat=' + position.coords.latitude + '&cur_lng=' + position.coords.longitude)
                        if (results.data.length > 0) {
                            this.setState({
                                busUsed: results.data[0].bus_id
                            })
                        }
                    }
                    getData();
                });
        } else {
            console.log("Geolocation is not supported by this browser.");
        }
    }


    getBusTransport = async () => {
        try {
            let results = await axios.get(API_URL + 'bus_transport')
            let arrOptions = results.data.map((data) => ({
                label: data['bus_name'],
                value: data['id'],
            }))
            arrOptions.unshift({
                label: 'ไม่ระบุ',
                value: ''
            })
            this.setState({
                optionsBusUsed: arrOptions
            })
        }
        catch (e) {
            console.log(e)
        }

    }

    //change state field for single select field form
    handleChangeSingleOption = (input) => (e) => {
        if (e) {
            this.setState({
                [input]: e.value,
            })
        } else {
            this.setState({
                [input]: "",
            })
        }
    }

    //change state field for general field form
    inputChange = (input) => (e) => {
        this.setState({
            [input]: e.target.value,
        });
    };


    //change state field for checkbox field form
    inputCheck = (input) => (e) => {
        this.setState({
            [input]: e.target.checked,
        })
    }

    //change date input 
    inputChangeDate = (input) => (e) => {
        this.setState({
            [input]: e.format("YYYY-MM-DD HH:mm"),
        });
    };

    handleChangePage = (pageView) => {
        this.setState({
            pageView: pageView,
            isLoadded: true
        })
    }

    onEditSelected = (dataEdit) => {
        this.setState({
            dataEditSelected: dataEdit,
            pageView: 'page-form',
            isLoadded: true
        })
    }

    handleSubmitForm = async (e) => {
        e.preventDefault();
        const {
            feedbackMessage,
            userLineID,
            starRating,
            curDate,
            dateUsed,
            timeUsed,
            busUsed,
            isSetDatetimeUsed
        } = this.state
        this.setState({
            isButtonLoaded: false
        })
        let dataBody = {
            "user_line_id": userLineID,
            "bus_id": busUsed,
            "feedback_message": feedbackMessage,
            "rating": starRating,
            "create_at": curDate,
        }
        if (isSetDatetimeUsed) {
            dataBody.time_used = timeUsed
            dataBody.date_used = dateUsed
        }
        axios({
            method: "POST",
            url: API_URL + "bus_feedback",
            headers: {
                "Content-Type": "application/json",
            },
            data: dataBody,
        })
            .then((result) => {
                this.setState({
                    isSummitedFeeback: true
                })
            }, (error) => {
                console.log(error)
            }
            )
    }

    render() {
        const {
            isSummitedFeeback,
            isLoadded,
            starRating,
            dateUsed,
            timeUsed,
            busUsed,
            feedbackMessage,
            isSetDatetimeUsed,
            optionsBusUsed
        } = this.state

        // const optionsBusUsed = [
        //     { label: "ไม่ระบุ", value: "" },
        //     { label: "รถมายเลข 1", value: 1 },
        //     { label: "รถมายเลข 2", value: 2 },
        //     { label: "รถมายเลข 3", value: 3 },
        // ]

        function GetPageView(props) {
            switch (props.pageView) {
                case 'page-list':
                    return (<ServiceBusAlertList {...props} />)

                case 'page-form':
                    return (<ServiceBusAlertEdit {...props} />)

                default:
                    return '';
            }
        }

        function StarIcon(props) {
            if (!props.isSelected) {
                return (<img height="30" src={require("../../../assets/icons/icon-star.png").default} />)
            } else {
                return (<img height="30" src={require("../../../assets/icons/icon-star-fill.png").default} />)
            }
        }

        return (
            <>
                {isLoadded ?
                    (
                        <div
                            style={{
                                backgroundColor: '#41826e',
                                fontFamily: 'kanit',
                                paddingTop: 12,
                                paddingBottom: 12,
                            }
                            }
                            className="min-vh-100 d-flex flex-row"
                        >

                            {!isSummitedFeeback ?
                                <CContainer >
                                    <CCard
                                    >
                                        <CForm onSubmit={this.handleSubmitForm}>
                                            <CCardBody
                                                style={{ minHeight: window.innerHeight * 0.8 }}
                                            >
                                                <CRow>
                                                    <CCol style={{ textAlign: 'center', marginBottom: 10 }}>
                                                        <img height="100" src={require("../../../assets/icons/su-char-icon.png").default} />
                                                    </CCol>
                                                </CRow>
                                                <CRow>
                                                    <CCol style={{ textAlign: 'center' }}>
                                                        <h4>ให้คะแนน {'&'} เสนอข้อแนะนำ</h4>
                                                    </CCol>
                                                </CRow>
                                                <CRow>
                                                    <CCol style={{ textAlign: 'center', padding: 0 }}>
                                                        <IconButton color="inherit" component="label" onClick={() => this.setState({ starRating: 1 })}>
                                                            <StarIcon isSelected={(starRating >= 1)} />
                                                        </IconButton>
                                                    </CCol>
                                                    <CCol style={{ textAlign: 'center', padding: 0 }}>
                                                        <IconButton color="inherit" component="label" onClick={() => this.setState({ starRating: 2 })}>
                                                            <StarIcon isSelected={(starRating >= 2)} />
                                                        </IconButton>
                                                    </CCol>
                                                    <CCol style={{ textAlign: 'center', padding: 0 }}>
                                                        <IconButton color="inherit" component="label" onClick={() => this.setState({ starRating: 3 })}>
                                                            <StarIcon isSelected={(starRating >= 3)} />
                                                        </IconButton>
                                                    </CCol>
                                                    <CCol style={{ textAlign: 'center', padding: 0 }}>
                                                        <IconButton color="inherit" component="label" onClick={() => this.setState({ starRating: 4 })}>
                                                            <StarIcon isSelected={(starRating >= 4)} />
                                                        </IconButton>
                                                    </CCol>
                                                    <CCol style={{ textAlign: 'center', padding: 0 }}>
                                                        <IconButton color="inherit" component="label" onClick={() => this.setState({ starRating: 5 })}>
                                                            <StarIcon isSelected={(starRating >= 5)} />
                                                        </IconButton>
                                                    </CCol>
                                                </CRow>
                                                <CRow>
                                                    <CCol style={{ marginTop: 15 }}>
                                                        <CFormLabel>
                                                            {"ระบุรถที่นั่ง"}
                                                        </CFormLabel>
                                                    </CCol>
                                                    {/* <CCol style={{ textAlign: 'right' }}>
                                                        {"ดูบนแผนที่"}
                                                        <IconButton color="inherit" component="label" >
                                                            <img height="25" src={require("../../../assets/icons/icon-map-location.png").default} />
                                                        </IconButton>
                                                    </CCol> */}
                                                </CRow>
                                                <CRow>
                                                    <CCol style={{ marginTop: 0 }}>
                                                        <Select
                                                            isSearchable={false}
                                                            className="basic-single"
                                                            classNamePrefix="select"
                                                            isClearable={false}
                                                            options={optionsBusUsed}
                                                            defaultSingleValue={busUsed}
                                                            onChange={this.handleChangeSingleOption("busUsed")}
                                                            placeholder="ไม่ระบุ"
                                                        />
                                                    </CCol>
                                                </CRow>
                                                <CRow>
                                                    <CCol
                                                        sm={12}
                                                        style={{ marginTop: 15 }}
                                                    >
                                                        <CFormCheck
                                                            id={"isSetDatetimeUsed"}
                                                            label={"ระบุวันและเวลาที่ใช้บริการ"}
                                                            onChange={this.inputCheck("isSetDatetimeUsed")}
                                                            checked={isSetDatetimeUsed}
                                                        />
                                                    </CCol>

                                                </CRow>

                                                {isSetDatetimeUsed &&
                                                    <CRow>
                                                        <CCol
                                                            style={{ marginTop: 15 }}
                                                        >
                                                            <CFormLabel>
                                                                {"วันที่ใช้บริการ"}
                                                                {/* <AsteriskPoint show={true} /> */}
                                                            </CFormLabel>
                                                            <DateInput
                                                                id="dateInput"
                                                                onChange={this.inputChangeDate('dateUsed')}
                                                                value={dateUsed}
                                                            />
                                                        </CCol>
                                                        <CCol
                                                            style={{ marginTop: 15 }}
                                                        >
                                                            <CFormLabel>
                                                                {"เวลาที่ใช้บริการ"}
                                                                {/* <AsteriskPoint show={true} /> */}
                                                            </CFormLabel>
                                                            <TimeInput
                                                                id="timeInput"
                                                                onChange={this.inputChangeDate('timeUsed')}
                                                                value={timeUsed}
                                                            />
                                                        </CCol>
                                                    </CRow>
                                                }
                                                <CRow>
                                                    <CCol
                                                        sm={12}
                                                        style={{ marginTop: 15 }}
                                                    >
                                                        <CFormTextarea
                                                            type="text"
                                                            id={"feedbackMessage"}
                                                            rows="4"
                                                            // maxLength={500}
                                                            onChange={this.inputChange("feedbackMessage")}
                                                            value={feedbackMessage}
                                                            placeholder="คำติมชมหรือคำแนะนำเพิ่มเติม..."
                                                        />
                                                    </CCol>
                                                </CRow>
                                            </CCardBody>
                                            <CCardFooter>
                                                <CRow>
                                                    <CCol
                                                        className="d-grid gap-2"
                                                        style={{ textAlign: 'center', paddingTop: 10, paddingBottom: 10 }}
                                                    >
                                                        <ButtonLoading
                                                            // isLoaded={isButtonLoaded}
                                                            variant="outline"
                                                            color="success"
                                                            type="submit"
                                                        >ส่ง</ButtonLoading>
                                                    </CCol>
                                                </CRow>
                                            </CCardFooter>
                                        </CForm>
                                    </CCard>
                                </CContainer >
                                :
                                <CContainer >
                                    {/* <CRow>
                                        <CCol style={{ textAlign: 'center', marginBottom: 10 }}>
                                            <img height="100" src={require("../../../assets/icons/su-char-icon.png").default} />
                                        </CCol>
                                    </CRow> */}
                                    <CCard
                                        style={{ paddingTop: 10, paddingBottom: 10 }}
                                    >
                                        <CCardBody>
                                            <CRow>
                                                <CCol style={{ textAlign: 'center', marginTop: 20 }}>
                                                    <img height="100" src={require("../../../assets/icons/su-char-icon.png").default} />
                                                </CCol>
                                            </CRow>
                                            <CRow>
                                                <CCol style={{ textAlign: 'center', marginTop: 10 }}>
                                                    <h4>ส่งคำติชมการใช้บริการเรียบร้อยแล้ว</h4>
                                                </CCol>
                                            </CRow>
                                            <CRow>
                                                <CCol style={{ textAlign: 'center', marginTop: 20 }}>
                                                    <CFormLabel>
                                                        ขอขอบคุณสำหรับคำแนะนำ<br></br>
                                                        เราจะนำไปพัฒนาการให้บริการให้ดียิ่งขึ้นต่อไป
                                                    </CFormLabel>
                                                </CCol>
                                            </CRow>
                                        </CCardBody>
                                    </CCard>
                                </CContainer >
                            }
                        </div>
                    ) :
                    (
                        <div
                            style={{ backgroundColor: '#41826e', fontFamily: 'kanit', paddingTop: 20 }}
                            className="min-vh-100 d-flex flex-row align-items-center" >
                            <CContainer>
                                <CRow className="justify-content-center">
                                    <CCol style={{ textAlign: 'center' }}>
                                        <CSpinner color="light" size="xxl" />
                                    </CCol>
                                </CRow>
                            </CContainer>
                        </div>
                    )
                }
            </>
        )
    }
}

export default FeedbackBus
