import React from 'react'
import {
    CButton,
    CSpinner
} from '@coreui/react'

function ButtonLoading(props) {
    var childrenData = props.children
    var isLoaded = props.isLoaded != undefined ? props.isLoaded : true
    var color = props.color != undefined ? props.color : "primary"
    var spinerType = props.spinerType != undefined ? props.spinerType : "border"//"grow"
    return (
        <>
            <CButton
                {...props}
                color={color}
                disabled={!isLoaded}
            >
                {childrenData}
                {!isLoaded &&
                    (
                        spinerType == "border" ?
                            <>
                                &nbsp;<CSpinner component="span" size="sm" aria-hidden="true" />
                            </>
                            :
                            <>
                                &nbsp; <CSpinner variant="grow" size="sm" aria-hidden="true" />
                            </>
                    )
                }
            </CButton>
        </>
    );
}

export default ButtonLoading;