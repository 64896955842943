import React, { useState, lazy } from "react"
import MomentUtils from "@date-io/moment";
import moment from "moment";
import OverwriteMomentBE from "./OverwriteMomentBE"
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import "moment/locale/th"

const DateInput = (props) => {
    var dateValue = props.value ? moment(props.value) : null
    return (
        <>
            <div className="container-date-input">
                <MuiPickersUtilsProvider utils={OverwriteMomentBE} locale={"th"}>
                    <DatePicker
                        {...props}
                        variant="inline"
                        inputProps={{
                            style: {
                                padding: "7px 12px"
                            }
                        }}
                        clearable
                        inputVariant="outlined"
                        format="DD / MM / YYYY"
                        value={dateValue}
                    />
                </MuiPickersUtilsProvider>
            </div>
        </>
    )
}

export default DateInput
