import React, { lazy } from "react"
import {
    CAvatar,
    CContainer,
    CButton,
    CButtonGroup,
    CInputGroupText,
    CCard,
    CCardBody,
    CCardFooter,
    CCardHeader,
    CCol,
    CProgress,
    CRow,
    CTable,
    CTableBody,
    CTableDataCell,
    CTableHead,
    CTableHeaderCell,
    CTableRow,
    CModal,
    CModalHeader,
    CModalBody,
    CModalFooter,
    CModalTitle,
    CForm,
    CFormLabel,
    CFormInput,
    CFormTextarea,
    CFormCheck,
    CInputGroup,
    CFormSwitch
} from "@coreui/react"
//base on react select component
//other fetures pls read https://react-select.com/
import Select from "../../../containers/SelectOption";
//=================================================
//Base on react core ui (CButton Component)
import ButtonLoading from "../../../containers/ButtonLoading"
import StepperForm from "../../../containers/StepperForm"
import ImageUploading from "react-images-uploading";
import SnackbarAlert from "../../../containers/SnackbarAlert"
import AsteriskPoint from "../../../containers/AsteriskPoint";
import DateInput from "../../../containers/DateInput"
import TimeInput from "../../../containers/TimeInput"
import CIcon from "@coreui/icons-react"
import { cilArrowThickLeft } from '@coreui/icons';
import axios from "axios";
import { Component } from "react"
import {
    API_URL
} from "../../../settings"
import {
    getCurDate,
    getStringDate
} from "../../../lib/util"
import { Icon, Button } from "@material-ui/core";
import liff from '@line/liff';
export class ServiceBusAlertForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            curDate: getCurDate(),
            isLoadded: false,
            isButtonLoaded: true,
            isEdit: false,
            name: '',
            userLineID: '',
            pictureUrl: '',
            stationID: '',
            stationName: '',
            stationID: '',
            alarmID: '',
            alarmName: '',
            timeAlarm: '01-01-2022 08:00:00',
            hourAlarm: '',
            minuteAlarm: '',
            dayAlarmTemp: [],
            dayAlarm: [],
            dayAlarmAdd: [],
            dayAlarmDel: [],
            alarmBeforMin: '',
            isAlarmBeforeTime: false,
            optionsBusStation: [],
            stationID: '',
            isInvalidHour: false,
            isInvalidMinute: false
        }
    }

    componentDidMount = async () => {
        await this.getBusStation();
        if (this.props.isEdit) {
            let dataAlarm = this.props.dataEditSelected
            this.setState({
                alarmID: dataAlarm.id,
                userLineID: dataAlarm.user_line_id,
                stationID: dataAlarm.bus_station_id,
                stationName: dataAlarm.station_name,
                hourAlarm: dataAlarm.time_alarm.split(':').length > 2 ? dataAlarm.time_alarm.split(':')[0] : '',
                minuteAlarm: dataAlarm.time_alarm.split(':').length > 2 ? dataAlarm.time_alarm.split(':')[1] : '',
                //timeAlarm: getStringDate(new Date('01-01-2021 ' + dataAlarm.time_alarm)),
                alarmName: dataAlarm.alarm_name,
                alarmBeforMin: dataAlarm.alarm_before_minute
            })
            this.genDayPart(dataAlarm.day_alarm)
        } else {
            this.setState({
                // stationID: 39,//dataAlarm.bus_station_id,
                // stationName: 'เพชรช็อป (โรงอาหารเพชรรัตน์)',//dataAlarm.bus_station_id,
                userLineID: this.props.userLineID,
            })
        }
        this.setState({
            isEdit: this.props.isEdit
        })
    }


    getBusStation = async () => {
        try {
            let results = await axios.get(API_URL + 'bus_station')
            let arrOptions = results.data.bus_station.map((data) => ({
                label: data['name'],
                value: data['id'],
            }))
            arrOptions.unshift({
                label: 'เลือกสถานี',
                value: ''
            })
            console.log(arrOptions)
            this.setState({
                optionsBusStation: arrOptions
            })
        }
        catch (e) {
            console.log(e)
        }

    }

    //change state field for single select field form
    handleChangeSingleOption = (input) => (e) => {
        if (e) {
            this.setState({
                [input]: e.value,
            })
        } else {
            this.setState({
                [input]: "",
            })
        }
    }

    genDayPart = (dayList) => {
        if (dayList.length > 0) {
            this.setState({
                dayAlarm: dayList.map((data) => data.day_of_week),
                dayAlarmTemp: dayList.map((data) => data.day_of_week)
            })
        }
    }

    //change date input 
    inputChangeDate = (input) => (e) => {
        this.setState({
            [input]: e.format("YYYY-MM-DD HH:mm"),
        });
    };

    //change state field for general field form
    inputChange = (input) => (e) => {
        this.setState({
            [input]: e.target.value,
        });
    };


    //change state field for general field form
    inputChangeTime = (input) => (e) => {
        if (input == 'hourAlarm') {
            let valueTime = e.target.value
            if (valueTime >= 0 && valueTime <= 23) {
                this.setState({
                    [input]: valueTime,
                    isInvalidHour: false,
                });
            } else {
                this.setState({
                    [input]: valueTime,
                    isInvalidHour: true,
                });
            }
        }
        if (input == 'minuteAlarm') {
            let valueTime = e.target.value
            if (valueTime >= 0 && valueTime <= 59) {
                this.setState({
                    [input]: valueTime,
                    isInvalidMinute: false,
                });
            } else {
                this.setState({
                    [input]: valueTime,
                    isInvalidMinute: true,
                });
            }
        }
    };

    onChangeAlarmBefore = (min) => {
        this.setState({
            alarmBeforMin: min,
        });
    };

    onSelectDay = (dayOfWeek) => {
        let newDayAlarm = this.state.dayAlarm
        let dayAlarmAdd = this.state.dayAlarmAdd
        let dayAlarmDel = this.state.dayAlarmDel
        let isExistDay = this.state.dayAlarm.some(data => (data == dayOfWeek))
        if (!(isExistDay)) {
            newDayAlarm.push(dayOfWeek)
            this.setState({
                dayAlarm: newDayAlarm,
            })
            //remove day form dayDelList
            if (this.state.dayAlarmDel.some(data => (data == dayOfWeek))) {
                let indexRemove = this.state.dayAlarmDel.findIndex(data => (data == dayOfWeek))
                dayAlarmDel.splice(indexRemove, 1)
                this.setState({
                    dayAlarmDel: dayAlarmDel
                })
            }
        }
        else {
            let indexRemove = this.state.dayAlarm.findIndex(data => (data == dayOfWeek))
            newDayAlarm.splice(indexRemove, 1)
            this.setState({
                dayAlarm: newDayAlarm
            })
            if (this.state.dayAlarmTemp.some(data => (data == dayOfWeek))) {
                dayAlarmDel.push(dayOfWeek)
                this.setState({
                    dayAlarmDel: dayAlarmDel
                })
            }
        }
        //case add new day 
        let isExistDayAdd = this.state.dayAlarmAdd.some(data => (data == dayOfWeek))
        let isExistDayTemp = this.state.dayAlarmTemp.some(data => (data == dayOfWeek))
        if (!(isExistDayAdd) && !(isExistDayTemp)) {
            dayAlarmAdd.push(dayOfWeek)
            this.setState({
                dayAlarmAdd: dayAlarmAdd
            })
        }
        else {
            let indexRemoveFromAddList = this.state.dayAlarmAdd.findIndex(data => (data == dayOfWeek))
            dayAlarmAdd.splice(indexRemoveFromAddList, 1)
            this.setState({
                dayAlarmAdd: dayAlarmAdd
            })
        }
        // console.log(dayAlarmAdd)
        // console.log('top = add , bottom = del')
        // console.log(dayAlarmDel)
    }

    getColorDayButton = (dayOfWeek) => {
        if (this.state.dayAlarm.some(data => (data == dayOfWeek))) {
            return 'secondary'
        } else {
            return 'light'
        }
    }

    handelInsertDayAlarm = (ref_id, dayAlarmAddList) => {
        const curDate = this.state.curDate
        return new Promise((resolve, reject) => {
            axios({
                method: "POST",
                url: API_URL + "bus_day_alert_list",
                headers: {
                    "Content-Type": "application/json",
                },
                data: {
                    "bus_alert_id": ref_id,
                    "day_list": dayAlarmAddList,
                    "create_at": curDate,
                },
            })
                .then((result) => {
                    resolve(true)
                }, (error) => {
                    resolve(false)
                }
                )
        })
    }

    //change state field for checkbox field form
    inputCheck = (input) => (e) => {
        this.setState({
            [input]: e.target.checked,
        });
        if (e.target.checked) {
            this.onChangeAlarmBefore('5')
        } else {
            this.onChangeAlarmBefore('')
        }
    };

    handelDeleteDayAlarm = (ref_id, dayAlarmDelList) => {
        return new Promise((resolve, reject) => {
            axios.delete(API_URL + "bus_day_alert", {
                headers: {
                    "Content-Type": "application/json",
                },
                data: {
                    "bus_alert_id": ref_id,
                    "day_list": dayAlarmDelList
                }
            })
                .then((result) => {
                    resolve(true)
                }, (error) => {
                    resolve(false)
                }
                )
        })
    }

    handleSubmitForm = async (e) => {
        e.preventDefault();
        const {
            alarmID,
            dayAlarm,
            alarmBeforMin,
            stationID,
            alarmName,
            //timeAlarm,
            hourAlarm,
            minuteAlarm,
            userLineID,
            isEdit,
            dayAlarmAdd,
            dayAlarmDel,
            isAlarmBeforeTime,
            curDate
        } = this.state
        this.setState({
            isButtonLoaded: false
        })
        // alert(typeof hourAlarm)
        // alert(typeof minuteAlarm)
        let _hourAlarm = (typeof hourAlarm == "number") ? hourAlarm.toString() : hourAlarm;
        let _minuteAlarm = (typeof minuteAlarm == "number") ? minuteAlarm.toString() : minuteAlarm;
        let timeAlarm = '01-01-2022 ' + _hourAlarm + ':' + _minuteAlarm + ':00'; // getStringDate(new Date('01-01-2021 ' + _hourAlarm + ':' + _minuteAlarm + ':00'))
        if (isEdit) {
            axios({
                method: "PUT",
                url: API_URL + "bus_services_alert/" + alarmID,
                headers: {
                    "Content-Type": "application/json",
                },
                data: {
                    "time_alarm": timeAlarm,
                    "bus_station_id": stationID,
                    "alarm_name": alarmName,
                    "alarm_before_minute": alarmBeforMin,
                    "is_alarm_before_time": isAlarmBeforeTime,
                    "update_at": curDate,
                },
            })
                .then((result) => {
                    let updateDayAlarm = async () => {
                        let isUpdateDayAlarm = true
                        if (dayAlarmAdd.length > 0) {
                            isUpdateDayAlarm = await this.handelInsertDayAlarm(alarmID, dayAlarmAdd);
                        }
                        if (dayAlarmDel.length > 0) {
                            isUpdateDayAlarm = await this.handelDeleteDayAlarm(alarmID, dayAlarmDel);
                        }
                        if (isUpdateDayAlarm) {
                            this.setState({
                                isButtonLoaded: true
                            })
                            this.props.reloadData()
                        }
                    }
                    updateDayAlarm()
                }, (error) => {
                    alert('ไม่สำเร็จ')
                    console.log(error)
                }
                )
        } else {
            axios({
                method: "POST",
                url: API_URL + "bus_services_alert",
                headers: {
                    "Content-Type": "application/json",
                },
                data: {
                    "user_line_id": userLineID,
                    "time_alarm": timeAlarm,
                    "bus_station_id": stationID,
                    "alarm_name": alarmName,
                    "alarm_before_minute": alarmBeforMin,
                    "alarm_enable": true,
                    "is_alarm_before_time": isAlarmBeforeTime,
                    "update_at": curDate,
                    "create_at": curDate,
                },
            })
                .then((result) => {
                    var refID = result.data.id
                    let insertDayAlarm = async () => {
                        let isUpdateDayAlarm = true
                        if (dayAlarmAdd.length > 0) {
                            isUpdateDayAlarm = await this.handelInsertDayAlarm(refID, dayAlarmAdd);
                        }
                        if (isUpdateDayAlarm) {
                            this.setState({
                                isButtonLoaded: true
                            })
                            this.props.reloadData()
                        }
                    }
                    insertDayAlarm()
                }, (error) => {
                    alert('ไม่สำเร็จ')
                    console.log(error)
                }
                )
        }
    }

    handleRemove = async () => {
        await axios.delete(API_URL + "bus_services_alert/" + this.state.alarmID);
        this.props.reloadData()
    }

    render() {
        const {
            isEdit,
            dayAlarm,
            alarmBeforMin,
            stationName,
            alarmName,
            timeAlarm,
            isButtonLoaded,
            isAlarmBeforeTime,
            hourAlarm,
            minuteAlarm,
            isInvalidDate,
            optionsBusStation,
            stationID,
            isInvalidMinute,
            isInvalidHour
        } = this.state
        return (
            <div
                style={{ backgroundColor: '#41826e', fontFamily: 'kanit', paddingTop: 20, paddingBottom: 20 }}
                className="min-vh-100 d-flex flex-row"
            >
                <CContainer >
                    <CCard>
                        <CForm onSubmit={this.handleSubmitForm}>
                            <CCardBody
                            >
                                <CRow>
                                    <CCol xs='2' style={{ textAlign: 'left', marginBottom: 10 }}>
                                        <CInputGroup>
                                            <button className="btn" style={{ padding: 0 }} onClick={() => this.props.handleChangePage('page-list')}>
                                                <img height="40" src={require("../../../assets/icons/icon-arrow-left-ios.png").default} />
                                            </button>
                                        </CInputGroup>
                                    </CCol>
                                    <CCol xs='10' style={{ textAlign: 'left', paddingTop: 10 }}>
                                        {isEdit ? <h4>{"แก้ไขการแจ้งเตือน"}</h4> : <h4>{"เพิ่มการแจ้งเตือน"}</h4>}
                                    </CCol>
                                    {/* <CCol xs='2' style={{ textAlign: 'left', marginBottom: 10, paddingTop: 5 }}>
                                        {isEdit && <CInputGroup>
                                            <button className="btn" style={{ padding: 0 }} onClick={() => this.handleRemove()}>
                                                <img height="25" src={require("../../../assets/icons/icon_trash.png").default} />
                                            </button>
                                        </CInputGroup>}
                                    </CCol> */}
                                </CRow>
                                <CRow>
                                    {/* <CCol style={{ padding: 0 }}></CCol>
                                    <CCol style={{ textAlign: 'center', marginBottom: 15 }}>
                                        <TimeInput
                                            id="timeInput"
                                            onChange={this.inputChangeDate('timeAlarm')}
                                            value={timeAlarm}
                                            variant="static"
                                        />
                                    </CCol>
                                    <CCol style={{ padding: 0 }}></CCol> */}
                                    <CCol style={{ padding: 0 }}></CCol>
                                    <CCol xs='4' style={{ textAlign: 'center', marginBottom: 10 }}>
                                        <CFormInput
                                            style={{ fontSize: 30 }}
                                            type="number"
                                            id={"hourAlarm"}
                                            required={true}
                                            min="0" max="23"
                                            invalid={isInvalidHour}
                                            onChange={this.inputChangeTime("hourAlarm")}
                                            value={hourAlarm}
                                            placeholder=""
                                        />
                                    </CCol>
                                    <CCol xs='1' style={{ textAlign: 'center', marginBottom: 10 }} ><h1>:</h1></CCol>
                                    <CCol xs='4' style={{ textAlign: 'center', marginBottom: 10 }}>
                                        <CFormInput
                                            style={{ fontSize: 30 }}
                                            type="number"
                                            id={"minuteAlarm"}
                                            required={true}
                                            invalid={isInvalidMinute}
                                            onChange={this.inputChangeTime("minuteAlarm")}
                                            value={minuteAlarm}
                                            placeholder=""
                                        />
                                    </CCol>
                                    <CCol style={{ padding: 0 }}></CCol>
                                </CRow>
                                {(isInvalidHour || isInvalidMinute) &&
                                    <CRow>
                                        <CCol
                                            sm={12}
                                            style={{ marginBottom: 15, textAlign: 'center' }}
                                        >
                                            <CFormLabel style={{ color: 'red', fontSize: 14 }}>
                                                กรอกรูปแบบเวลาไม่ถูกต้อง!
                                            </CFormLabel>
                                        </CCol>
                                    </CRow>
                                }
                                <CRow>
                                    <CCol style={{ textAlign: 'center', paddingRight: 0 }}>
                                        <CButton
                                            color={this.getColorDayButton(0)}
                                            shape="rounded-pill"
                                            onClick={() => this.onSelectDay(0)}
                                        >
                                            <span style={{ color: 'red' }}>อา</span>
                                        </CButton>
                                    </CCol>
                                    <CCol
                                        style={{ textAlign: 'center', paddingRight: 0 }} >
                                        <CButton
                                            color={this.getColorDayButton(1)}
                                            shape="rounded-pill"
                                            onClick={() => this.onSelectDay(1)}
                                        >
                                            จ
                                        </CButton>
                                    </CCol>
                                    <CCol style={{ textAlign: 'center', paddingRight: 0 }}>
                                        <CButton
                                            color={this.getColorDayButton(2)}
                                            shape="rounded-pill"
                                            onClick={() => this.onSelectDay(2)}
                                        >
                                            อ
                                        </CButton>
                                    </CCol>
                                    <CCol style={{ textAlign: 'center', paddingRight: 0 }} >
                                        <CButton
                                            color={this.getColorDayButton(3)}
                                            shape="rounded-pill"
                                            onClick={() => this.onSelectDay(3)}
                                        >
                                            พ
                                        </CButton>
                                    </CCol>
                                    <CCol style={{ textAlign: 'center', paddingRight: 0 }}>
                                        <CButton
                                            color={this.getColorDayButton(4)}
                                            shape="rounded-pill"
                                            onClick={() => this.onSelectDay(4)}
                                        >
                                            พฤ
                                        </CButton>
                                    </CCol>
                                    <CCol style={{ textAlign: 'center', paddingRight: 0 }} >
                                        <CButton
                                            color={this.getColorDayButton(5)}
                                            shape="rounded-pill"
                                            onClick={() => this.onSelectDay(5)}
                                        >
                                            ศ
                                        </CButton>
                                    </CCol>
                                    <CCol style={{ textAlign: 'center', paddingRight: 0 }} >
                                        <CButton
                                            color={this.getColorDayButton(6)}
                                            shape="rounded-pill"
                                            onClick={() => this.onSelectDay(6)}
                                        >
                                            ส
                                        </CButton>
                                    </CCol>
                                </CRow>
                                <CRow>
                                    <CCol
                                        sm={12}
                                        style={{ marginTop: 15 }}
                                    >
                                        <CFormLabel>
                                            สถานี
                                        </CFormLabel>
                                    </CCol>
                                </CRow>
                                <CRow>
                                    <CCol >
                                        <Select
                                            isSearchable={false}
                                            required={true}
                                            className="basic-single"
                                            classNamePrefix="select"
                                            isClearable={false}
                                            options={optionsBusStation}
                                            defaultSingleValue={stationID}
                                            onChange={this.handleChangeSingleOption("stationID")}
                                            placeholder="เลือกสถานี..."
                                        />
                                    </CCol>
                                </CRow>
                                <CRow>
                                    <CCol
                                        sm={12}
                                        style={{ marginTop: 15 }}
                                    >
                                        <CFormLabel>
                                            ชื่อการแจ้งเตือน
                                        </CFormLabel>
                                    </CCol>
                                </CRow>

                                <CRow>
                                    <CCol
                                        sm={12}
                                    >
                                        <CFormInput
                                            type="text"
                                            id={"name"}
                                            maxLength={256}
                                            onChange={this.inputChange("alarmName")}
                                            value={alarmName}
                                            placeholder=""
                                        />
                                    </CCol>
                                </CRow>
                                {/* <CRow>
                                    <CCol style={{ marginBottom: 10 }}>
                                        <CFormLabel></CFormLabel>
                                        <CFormCheck
                                            label={"แจ้งเตือนก่อนเวลา"}
                                            onChange={this.inputCheck("isAlarmBeforeTime")}
                                            checked={isAlarmBeforeTime}
                                        />
                                    </CCol>
                                </CRow>
                                {isAlarmBeforeTime &&
                                    <CRow>
                                        <CCol style={{ paddingRight: 0 }}>
                                            <CButton
                                                color={alarmBeforMin == '5' ? 'secondary' : 'light'}
                                                onClick={() => this.onChangeAlarmBefore('5')}
                                            > 5 นาที
                                            </CButton>
                                        </CCol>
                                        <CCol
                                            style={{ paddingRight: 0 }} >
                                            <CButton
                                                color={alarmBeforMin == '10' ? 'secondary' : 'light'}
                                                onClick={() => this.onChangeAlarmBefore('10')}
                                            >
                                                10 นาที
                                            </CButton>
                                        </CCol>
                                        <CCol
                                            style={{ paddingRight: 0 }} >
                                            <CButton
                                                color={alarmBeforMin == '15' ? 'secondary' : 'light'}
                                                onClick={() => this.onChangeAlarmBefore('15')}
                                            >
                                                15 นาที
                                            </CButton>
                                        </CCol>
                                        <CCol
                                            style={{ paddingRight: 0 }} >
                                            <CButton
                                                color={alarmBeforMin == '20' ? 'secondary' : 'light'}
                                                onClick={() => this.onChangeAlarmBefore('20')}
                                            >
                                                20 นาที
                                            </CButton>
                                        </CCol>
                                    </CRow>} */}
                            </CCardBody>
                            <CCardFooter>
                                <CRow>
                                    <CCol
                                        className="d-grid gap-2"
                                        style={{ textAlign: 'center', marginTop: 10, marginBottom: 10 }}
                                        xs={6}
                                    >
                                        <CButton
                                            type="reset"
                                            variant="outline"
                                            color="secondary"
                                            onClick={() => this.props.handleChangePage('page-list')}
                                        >ยกเลิก</CButton>
                                    </CCol>
                                    <CCol
                                        className="d-grid gap-2"
                                        style={{ textAlign: 'center', marginTop: 10, marginBottom: 10 }}
                                        xs={6}
                                    >
                                        <ButtonLoading
                                            isLoaded={isButtonLoaded}
                                            variant="outline"
                                            color="success"
                                            type="submit"
                                        >บันทึก</ButtonLoading>
                                    </CCol>
                                </CRow>
                            </CCardFooter>
                        </CForm>
                    </CCard>
                </CContainer >
            </div >
        )
    }
}

export default ServiceBusAlertForm
