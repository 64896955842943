import React, { lazy } from "react"
import {
    CAvatar,
    CContainer,
    CButton,
    CButtonGroup,
    CInputGroupText,
    CCard,
    CCardBody,
    CCardFooter,
    CCardHeader,
    CCol,
    CProgress,
    CRow,
    CTable,
    CTableBody,
    CTableDataCell,
    CTableHead,
    CTableHeaderCell,
    CTableRow,
    CModal,
    CModalHeader,
    CModalBody,
    CModalFooter,
    CModalTitle,
    CForm,
    CFormLabel,
    CFormInput,
    CFormTextarea,
    CFormCheck,
    CInputGroup,
    CFormSwitch,
    CSpinner
} from "@coreui/react"
//base on react select component
//other fetures pls read https://react-select.com/
import Select from "../../../containers/SelectOption";
//=================================================
import StepperForm from "../../../containers/StepperForm"
import ImageUploading from "react-images-uploading";
import SnackbarAlert from "../../../containers/SnackbarAlert"
import AsteriskPoint from "../../../containers/AsteriskPoint";
import DateInput from "../../../containers/DateInput"
import CIcon from "@coreui/icons-react"
import axios from "axios";
import { Component } from "react"
import {
    API_URL
} from "../../../settings"
import {
    getCurDate
} from "../../../lib/util"
import { Icon } from "@material-ui/core";
import liff from '@line/liff';
import ServiceBusAlertList from "./ServiceButAlertList";
import ServiceBusAlertForm from "./ServiceBusAlertForm";
import { ThemeConsumer } from "react-bootstrap/esm/ThemeProvider";

export class ServiceBusAlert extends Component {
    constructor(props) {
        super(props);
        this.state = {
            curDate: getCurDate(),
            isLoadded: false,
            isEdit: false,
            pageView: '',
            name: '',
            userLineID: '',
            pictureUrl: '',
            alarmList: [],
            dataEditSelected: ''
        }
    }

    componentDidMount = async () => {
        await liff.init({ liffId: '1657406254-l9RBa9dQ' }).catch(err => { throw err });
        if (liff.isLoggedIn()) {
            let getProfile = await liff.getProfile();
            let getIDToken = await liff.getIDToken();
            console.log("---------userID-----------")
            console.log(getProfile.userId);
            console.log("---------getIDToken-----------")
            console.log(getIDToken);
            this.setState({
                name: getProfile.displayName,
                userLineID: getProfile.userId,
                pictureUrl: getProfile.pictureUrl,
            });
        } else {
            liff.login();
        }
        this.reloadData()
    }

    reloadData = async () => {
        let { userLineID } = this.state
        this.setState({
            isLoadded: false
        })
        try {
            let results = await axios.get(API_URL + "bus_services_alert" + "?user_id=" + userLineID
            )
            this.setState({
                pageView: 'page-list',
                alarmList: results.data,
                isLoadded: true
            })
        }
        catch (e) {
            alert(e)
            console.log(e)
            this.setState({
                pageView: 'page-list',
                isLoadded: true
            })
        }
    }

    handleChangePage = (pageView) => {
        this.setState({
            pageView: pageView,
            isLoadded: true
        })
    }

    onEditSelected = (dataEdit) => {
        this.setState({
            dataEditSelected: dataEdit,
            pageView: 'page-form',
            isEdit: true,
            isLoadded: true
        })
    }

    onAddAlarm = () => {
        this.setState({
            dataEditSelected: '',
            pageView: 'page-form',
            isEdit: false,
            isLoadded: true
        })
    }

    render() {
        const {
            isEdit,
            userLineID,
            isLoadded,
            pageView,
            alarmList,
            dataEditSelected
        } = this.state

        function GetPageView(props) {
            switch (props.pageView) {
                case 'page-list':
                    return (<ServiceBusAlertList {...props} />)

                case 'page-form':
                    return (<ServiceBusAlertForm {...props} />)

                default:
                    return '';
            }
        }

        return (
            <>
                {/* <p>{userLineID}</p> */}
                {console.log(pageView)}
                {isLoadded ?
                    (
                        <GetPageView
                            isEdit={isEdit}
                            userLineID={userLineID}
                            pageView={pageView}
                            dataEditSelected={dataEditSelected}
                            alarmList={alarmList}
                            handleChangePage={this.handleChangePage}
                            onAddAlarm={this.onAddAlarm}
                            onEditSelected={this.onEditSelected}
                            reloadData={this.reloadData}
                        />
                    ) :
                    (
                        <div
                            style={{ backgroundColor: '#41826e', fontFamily: 'kanit', paddingTop: 20 }}
                            className="min-vh-100 d-flex flex-row align-items-center" >
                            <CContainer>
                                <CRow className="justify-content-center">
                                    <CCol style={{ textAlign: 'center' }}>
                                        <CSpinner color="light" size="xxl" />
                                    </CCol>
                                </CRow>
                            </CContainer>
                        </div>
                    )
                }
            </>
        )
    }
}

export default ServiceBusAlert
