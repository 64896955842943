import React, { Component } from 'react'
import { Route, Switch, BrowserRouter } from 'react-router-dom'
import './scss/style.scss'
import FeedbackBus from './views/pages/feedback-bus/FeedbackBus'
import ServiceBusAlert from './views/pages/service-bus-alert/ServiceBusAlert'
import BusDriver from './views/pages/bus-driver/BusDriver'
import BusTransport from './views/pages/bus-transport/BusTransport'
import DriverRegister from './views/pages/driver-register/DriverRegister'
import UpdateServiceBus from './views/pages/update-service-bus/UpdateServiceBus'
import AdminRegister from './views/pages/admin-register/AdminRegister'

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <Switch>
          <Route exact path="/" name="Feedback" render={(props) => <FeedbackBus {...props} />} />
          <Route exact path="/servicesbusalert" name="Service Bus Alert" render={(props) => <ServiceBusAlert {...props} />} />
          <Route exact path="/busfeedback" name="Service Bus Alert" render={(props) => <FeedbackBus {...props} />} />
          <Route exact path="/busdriver" name="Bus Driver" render={(props) => <BusDriver {...props} />} />
          <Route exact path="/bustransport" name="Bus Driver" render={(props) => <BusTransport {...props} />} />
          <Route exact path="/driverregister" name="Driver register" render={(props) => <DriverRegister {...props} />} />
          <Route exact path="/updateservicebus" name="Driver register" render={(props) => <UpdateServiceBus {...props} />} />
          <Route exact path="/adminregister" name="Admin register" render={(props) => <AdminRegister {...props} />} />
        </Switch>
      </BrowserRouter>
    )
  }
}

export default App
