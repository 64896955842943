import React, { lazy } from "react"
import {
    CAvatar,
    CContainer,
    CButton,
    CButtonGroup,
    CInputGroupText,
    CCard,
    CCardBody,
    CCardFooter,
    CCardHeader,
    CCol,
    CProgress,
    CRow,
    CTable,
    CTableBody,
    CTableDataCell,
    CTableHead,
    CTableHeaderCell,
    CTableRow,
    CModal,
    CModalHeader,
    CModalBody,
    CModalFooter,
    CModalTitle,
    CForm,
    CFormLabel,
    CFormInput,
    CFormTextarea,
    CFormCheck,
    CInputGroup,
    CFormSwitch
} from "@coreui/react"
//base on react select component
//other fetures pls read https://react-select.com/
import Select from "../../../containers/SelectOption";
//=================================================
//Base on react core ui (CButton Component)
import ButtonLoading from "../../../containers/ButtonLoading"
import StepperForm from "../../../containers/StepperForm"
import ImageUploading from "react-images-uploading";
import SnackbarAlert from "../../../containers/SnackbarAlert"
import AsteriskPoint from "../../../containers/AsteriskPoint";
import DateInput from "../../../containers/DateInput"
import TimeInput from "../../../containers/TimeInput"
import CIcon from "@coreui/icons-react"
import { cilArrowThickLeft } from '@coreui/icons';
import axios from "axios";
import { Component } from "react"
import {
    API_URL
} from "../../../settings"
import {
    getCurDate,
    getStringDate
} from "../../../lib/util"
import { Icon, Button } from "@material-ui/core";
import liff from '@line/liff';
export class BusDriverForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            curDate: getCurDate(),
            isLoadded: false,
            isButtonLoaded: true,
            isEdit: false,
            isShowConfirmDialog: false,
            name: '',
            userLineID: '',
            pictureUrl: '',
            id: '',
            firstName: '',
            lastName: '',
            phoneNo: '',
        }
    }

    componentDidMount = async () => {
        if (this.props.isEdit) {
            let listData = this.props.dataEditSelected
            this.setState({
                id: listData.id,
                firstName: listData.first_name,
                lastName: listData.last_name,
                phoneNo: listData.phone_no,
            })
        }
        this.setState({
            isEdit: this.props.isEdit
        })
    }


    //change date input 
    inputChangeDate = (input) => (e) => {
        this.setState({
            [input]: e.format("YYYY-MM-DD HH:mm"),
        });
    };

    //change state field for general field form
    inputChange = (input) => (e) => {
        this.setState({
            [input]: e.target.value,
        });
    };


    handleSubmitForm = async (e) => {
        e.preventDefault();
        const {
            isEdit,
            id,
            firstName,
            lastName,
            phoneNo,
            curDate,
            isShowConfirmDialog
        } = this.state
        this.setState({
            isButtonLoaded: false
        })
        if (isEdit) {
            axios({
                method: "PUT",
                url: API_URL + "bus_driver/" + id,
                headers: {
                    "Content-Type": "application/json",
                },
                data: {
                    "first_name": firstName,
                    "last_name": lastName,
                    "phone_no": phoneNo,
                    "update_at": curDate,
                },
            })
                .then((result) => {
                    this.props.reloadData()
                }, (error) => {
                    console.log(error)
                }
                )
        } else {
            axios({
                method: "POST",
                url: API_URL + "bus_driver",
                headers: {
                    "Content-Type": "application/json",
                },
                data: {
                    "first_name": firstName,
                    "last_name": lastName,
                    "phone_no": phoneNo,
                    "create_at": curDate,
                    "update_at": curDate,
                },
            })
                .then((result) => {
                    this.props.reloadData()
                }, (error) => {
                    console.log(error)
                }
                )
        }
    }

    render() {
        const {
            isButtonLoaded,
            id,
            firstName,
            lastName,
            phoneNo,
            curDate
        } = this.state
        return (
            <div
                style={{ backgroundColor: '#41826e', fontFamily: 'kanit', paddingTop: 12, paddingBottom: 12 }}
                className="min-vh-100 d-flex flex-row"
            >
                <CContainer >
                    <CCard>
                        <CForm onSubmit={this.handleSubmitForm}>
                            <CCardBody
                                style={{ minHeight: window.innerHeight * 0.8 }}
                            >
                                <CRow>
                                    <CCol style={{ textAlign: 'center', marginBottom: 10 }}>
                                        <img height="80" src={require("../../../assets/icons/su-char-icon.png").default} />
                                    </CCol>
                                </CRow>
                                <CRow>
                                    <CCol xs='2' style={{ textAlign: 'left', marginBottom: 10 }}>
                                        <CInputGroup>
                                            <button className="btn" style={{ padding: 0 }} onClick={() => this.props.handleChangePage('page-list')}>
                                                <img height="40" src={require("../../../assets/icons/icon-arrow-left-ios.png").default} />
                                            </button>
                                        </CInputGroup>
                                    </CCol>
                                    <CCol xs='10' style={{ textAlign: 'left', paddingTop: 10 }}>
                                        <h4>{'แก้ไขข้อมูลคนขับรถบัส'}</h4>
                                    </CCol>
                                </CRow>
                                <CRow>
                                    <CCol
                                        sm={12}
                                        style={{ marginTop: 15 }}
                                    >
                                        <CFormLabel>
                                            ชื่อ
                                        </CFormLabel>
                                    </CCol>
                                </CRow>
                                <CRow>
                                    <CCol
                                        sm={12}
                                    >
                                        <CFormInput
                                            type="text"
                                            id={"firstName"}
                                            required={true}
                                            maxLength={256}
                                            onChange={this.inputChange("firstName")}
                                            value={firstName}
                                            placeholder=""
                                        />
                                    </CCol>
                                </CRow>
                                <CRow>
                                    <CCol
                                        sm={12}
                                        style={{ marginTop: 15 }}
                                    >
                                        <CFormLabel>
                                            นามสกุล
                                        </CFormLabel>
                                    </CCol>
                                </CRow>
                                <CRow>
                                    <CCol
                                        sm={12}
                                    >
                                        <CFormInput
                                            type="text"
                                            id={"lastName"}
                                            required={true}
                                            maxLength={256}
                                            onChange={this.inputChange("lastName")}
                                            value={lastName}
                                            placeholder=""
                                        />
                                    </CCol>
                                </CRow>

                                <CRow>
                                    <CCol
                                        sm={12}
                                        style={{ marginTop: 15 }}
                                    >
                                        <CFormLabel>
                                            โทรศัพท์
                                        </CFormLabel>
                                    </CCol>
                                </CRow>
                                <CRow>
                                    <CCol
                                        sm={12}
                                    >
                                        <CFormInput
                                            type="text"
                                            id={"phoneNo"}
                                            required={true}
                                            maxLength={10}
                                            onChange={this.inputChange("phoneNo")}
                                            value={phoneNo}
                                            placeholder=""
                                        />
                                    </CCol>
                                </CRow>


                            </CCardBody>
                            <CCardFooter>
                                <CRow>
                                    <CCol
                                        className="d-grid gap-2"
                                        style={{ textAlign: 'center', marginTop: 10, marginBottom: 10 }}
                                        xs={6}
                                    >
                                        <CButton
                                            type="reset"
                                            variant="outline"
                                            color="secondary"
                                            onClick={() => this.props.handleChangePage('page-list')}
                                        >ยกเลิก</CButton>
                                    </CCol>
                                    <CCol
                                        className="d-grid gap-2"
                                        style={{ textAlign: 'center', marginTop: 10, marginBottom: 10 }}
                                        xs={6}
                                    >
                                        <ButtonLoading
                                            isLoaded={isButtonLoaded}
                                            variant="outline"
                                            color="success"
                                            type="submit"
                                        >บันทึก</ButtonLoading>
                                    </CCol>
                                </CRow>
                            </CCardFooter>
                        </CForm>
                    </CCard>
                </CContainer >
            </div >
        )
    }
}

export default BusDriverForm
