import React, { useState } from 'react';
import Box from '@material-ui/core/Box';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepperHeader from './StepperHeader'
import {
    CAvatar,
    CAlert,
    CButton,
    CButtonGroup,
    CCard,
    CCardBody,
    CCardFooter,
    CCardHeader,
    CCol,
    CProgress,
    CRow,
    CTable,
    CTableBody,
    CTableDataCell,
    CTableHead,
    CTableHeaderCell,
    CTableRow,
    CListGroup,
    CListGroupItem,
    CFormSwitch,
    CForm,
    CFormLabel,
    CFormInput,
    CFormTextarea,
    CModal,
    CTooltip,
    CModalBody,
    CModalFooter,
    CModalHeader,
    CModalTitle,
} from "@coreui/react";

const StepperForm = (props) => {
    var isStepper = props.isStepper
    var childrenData = props.children
    var isLastSection = props.isLastSection
    var amountStep = props.amountStep
    const [buttonClick, setButtonClick] = useState('')
    return (
        <>
            {isStepper &&
                <StepperHeader {...props} />
            }
            <CForm onSubmit={() => props.onClickButtonForm(buttonClick)}>
                {childrenData}
                {
                    isStepper ?
                        (!isLastSection ?
                            (<CRow>
                                <CCol style={{ marginTop: 20, textAlign: "center" }}>
                                    {props.stepForm != 0 &&
                                        <CButton color="secondary" style={{ width: 100 }} onClick={() => props.onClickButtonForm("prev")}>ย้อนกลับ</CButton>
                                    }
                                    <CButton type="submit" style={{ width: 100, marginLeft: 5 }} color="info" onClick={() => setButtonClick("next")}>ต่อไป</CButton>
                                </CCol>
                            </CRow>)
                            :
                            (<CRow>
                                <CCol style={{ marginTop: 20, textAlign: "center" }}>
                                    <CButton style={{ width: 100 }} color="secondary" onClick={() => props.onClickButtonForm("prev")}>Previous</CButton>
                                    <CButton type="submit" style={{ width: 100, marginLeft: 5 }} color="success" onClick={() => setButtonClick("submit")}>บันทึก</CButton>
                                </CCol>
                            </CRow>)
                        )
                        :
                        (
                            <CRow>
                                <CCol style={{ marginTop: 20, textAlign: "center" }}>
                                    <CButton type="submit" style={{ width: 100, marginLeft: 5 }} color="success" onClick={() => setButtonClick("submit")}>บันทึก</CButton>
                                </CCol>
                            </CRow>
                        )
                }

            </CForm>
        </>
    );
}
export default StepperForm
