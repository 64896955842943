import React from 'react';
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';

const SnackbarAlert = (props) => {
    var isShowAlert = props.isShowAlert;
    var autoHideTime = 1500;
    var colorAlert = props.colorAlert;
    var messageAlert = props.messageAlert;
    return (
        <Snackbar open={isShowAlert} autoHideDuration={autoHideTime} onClose={props.onClose}>
            <MuiAlert variant="filled" severity={colorAlert} onClose={props.onClose}>
                {messageAlert}
            </MuiAlert>
        </Snackbar>
    )
}
// Color =>
// 'error'
// | 'info'
// | 'success'
// | 'warning'
export default SnackbarAlert
