import React, { lazy } from "react"
import {
    CAvatar,
    CContainer,
    CButton,
    CButtonGroup,
    CInputGroupText,
    CCard,
    CCardBody,
    CCardFooter,
    CCardHeader,
    CCol,
    CProgress,
    CRow,
    CTable,
    CTableBody,
    CTableDataCell,
    CTableHead,
    CTableHeaderCell,
    CTableRow,
    CModal,
    CModalHeader,
    CModalBody,
    CModalFooter,
    CModalTitle,
    CForm,
    CFormLabel,
    CFormInput,
    CFormTextarea,
    CFormCheck,
    CInputGroup,
    CFormSwitch
} from "@coreui/react"
//base on react select component
//other fetures pls read https://react-select.com/
import Select from "../../../containers/SelectOption";
//=================================================
//Base on react core ui (CButton Component)
import ButtonLoading from "../../../containers/ButtonLoading"
import StepperForm from "../../../containers/StepperForm"
import ImageUploading from "react-images-uploading";
import SnackbarAlert from "../../../containers/SnackbarAlert"
import AsteriskPoint from "../../../containers/AsteriskPoint";
import DateInput from "../../../containers/DateInput"
import TimeInput from "../../../containers/TimeInput"
import CIcon from "@coreui/icons-react"
import { cilArrowThickLeft } from '@coreui/icons';
import axios from "axios";
import { Component } from "react"
import {
    API_URL
} from "../../../settings"
import {
    getCurDate,
    getStringDate
} from "../../../lib/util"
import { Icon, Button } from "@material-ui/core";
import liff from '@line/liff';
export class BusTransportForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            curDate: getCurDate(),
            isLoadded: false,
            isButtonLoaded: true,
            isEdit: false,
            isShowConfirmDialog: false,
            name: '',
            userLineID: '',
            pictureUrl: '',
            id: '',
            busName: '',
            maxSeat: '',
            driverID: '',
            optionsDriver: []
        }
    }

    componentDidMount = async () => {
        if (this.props.isEdit) {
            let listData = this.props.dataEditSelected
            this.setState({
                id: listData.id,
                busName: listData.bus_name,
                maxSeat: listData.max_seat,
                driverID: listData.driver_id,
            })
        }
        this.getBusDriver()
        this.setState({
            isEdit: this.props.isEdit
        })
    }

    getBusDriver = async () => {
        try {
            let results = await axios.get(API_URL + 'bus_driver')
            let arrOptions = results.data.map((data) => ({
                label: data['first_name'] + ' ' + data['last_name'],
                value: data['id'],
            }))
            arrOptions.unshift({
                label: 'ไม่ระบุผู้ขับ',
                value: ''
            })
            this.setState({
                optionsDriver: arrOptions
            })
        }
        catch (e) {
            console.log(e)
        }

    }

    //change date input 
    inputChangeDate = (input) => (e) => {
        this.setState({
            [input]: e.format("YYYY-MM-DD HH:mm"),
        });
    };

    //change state field for general field form
    inputChange = (input) => (e) => {
        this.setState({
            [input]: e.target.value,
        });
    };

    //change state field for single select field form
    handleChangeSingleOption = (input) => (e) => {
        if (e) {
            this.setState({
                [input]: e.value,
            })
        } else {
            this.setState({
                [input]: "",
            })
        }
    }


    handleSubmitForm = async (e) => {
        e.preventDefault();
        const {
            isEdit,
            id,
            busName,
            maxSeat,
            driverID,
            curDate,
            isShowConfirmDialog
        } = this.state
        this.setState({
            isButtonLoaded: false
        })
        if (isEdit) {
            axios({
                method: "PUT",
                url: API_URL + "bus_transport/" + id,
                headers: {
                    "Content-Type": "application/json",
                },
                data: {
                    "bus_name": busName,
                    "max_seat": maxSeat,
                    "driver_id": driverID,
                    "update_at": curDate,
                },
            })
                .then((result) => {
                    this.props.reloadData()
                }, (error) => {
                    console.log(error)
                }
                )
        } else {
            axios({
                method: "POST",
                url: API_URL + "bus_transport",
                headers: {
                    "Content-Type": "application/json",
                },
                data: {
                    "bus_name": busName,
                    "max_seat": maxSeat,
                    "driver_id": driverID,
                    "create_at": curDate,
                    "update_at": curDate,
                },
            })
                .then((result) => {
                    this.props.reloadData()
                }, (error) => {
                    console.log(error)
                }
                )
        }
    }

    render() {
        const {
            isButtonLoaded,
            id,
            busName,
            maxSeat,
            curDate,
            driverID,
            optionsDriver
        } = this.state
        return (
            <div
                style={{
                    backgroundColor: '#41826e',
                    fontFamily: 'kanit',
                    paddingTop: 12,
                    paddingBottom: 12,
                }
                }
                className="min-vh-100 d-flex flex-row"
            >
                <CContainer >
                    <CCard
                    >
                        <CForm onSubmit={this.handleSubmitForm}>
                            <CCardBody
                                style={{ minHeight: window.innerHeight * 0.8 }}
                            >
                                <CRow>
                                    <CCol style={{ textAlign: 'center', marginBottom: 10 }}>
                                        <img height="80" src={require("../../../assets/icons/su-char-icon.png").default} />
                                    </CCol>
                                </CRow>
                                <CRow>
                                    <CCol xs='2' style={{ textAlign: 'left', marginBottom: 10 }}>
                                        <CInputGroup>
                                            <button className="btn" style={{ padding: 0 }} onClick={() => this.props.handleChangePage('page-list')}>
                                                <img height="40" src={require("../../../assets/icons/icon-arrow-left-ios.png").default} />
                                            </button>
                                        </CInputGroup>
                                    </CCol>
                                    <CCol xs='10' style={{ textAlign: 'left', paddingTop: 10 }}>
                                        <h4>{'แก้ไขข้อมูลรถบัส'}</h4>
                                    </CCol>
                                </CRow>
                                <CRow>
                                    <CCol
                                        sm={12}
                                        style={{ marginTop: 15 }}
                                    >
                                        <CFormLabel>
                                            ชื่อรถบัส
                                        </CFormLabel>
                                    </CCol>
                                </CRow>
                                <CRow>
                                    <CCol
                                        sm={12}
                                    >
                                        <CFormInput
                                            type="text"
                                            id={"busName"}
                                            required={true}
                                            maxLength={256}
                                            onChange={this.inputChange("busName")}
                                            value={busName}
                                            placeholder=""
                                        />
                                    </CCol>
                                </CRow>
                                <CRow>
                                    <CCol
                                        sm={12}
                                        style={{ marginTop: 15 }}
                                    >
                                        <CFormLabel>
                                            ความจุผู้โดยสาร
                                        </CFormLabel>
                                    </CCol>
                                </CRow>
                                <CRow>
                                    <CCol
                                        sm={12}
                                    >
                                        <CFormInput
                                            type="text"
                                            id={"maxSeat"}
                                            required={true}
                                            maxLength={256}
                                            onChange={this.inputChange("maxSeat")}
                                            value={maxSeat}
                                            placeholder=""
                                        />
                                    </CCol>
                                </CRow>

                                <CRow>
                                    <CCol
                                        sm={12}
                                        style={{ marginTop: 15 }}
                                    >
                                        <CFormLabel>
                                            พนักงานขับรถ
                                        </CFormLabel>
                                    </CCol>
                                </CRow>
                                <CRow>
                                    <CCol style={{ marginTop: 0 }}>
                                        <Select
                                            className="basic-single"
                                            classNamePrefix="select"
                                            isClearable={false}
                                            options={optionsDriver}
                                            defaultSingleValue={driverID}
                                            onChange={this.handleChangeSingleOption("driverID")}
                                            placeholder="ไม่ระบุผู้ขับ"
                                        />
                                    </CCol>
                                </CRow>

                            </CCardBody>
                            <CCardFooter>
                                <CRow>
                                    <CCol
                                        className="d-grid gap-2"
                                        style={{ textAlign: 'center', marginTop: 10, marginBottom: 10 }}
                                        xs={6}
                                    >
                                        <CButton
                                            type="reset"
                                            variant="outline"
                                            color="secondary"
                                            onClick={() => this.props.handleChangePage('page-list')}
                                        >ยกเลิก</CButton>
                                    </CCol>
                                    <CCol
                                        className="d-grid gap-2"
                                        style={{ textAlign: 'center', marginTop: 10, marginBottom: 10 }}
                                        xs={6}
                                    >
                                        <ButtonLoading
                                            isLoaded={isButtonLoaded}
                                            variant="outline"
                                            color="success"
                                            type="submit"
                                        >บันทึก</ButtonLoading>
                                    </CCol>
                                </CRow>
                            </CCardFooter>
                        </CForm>
                    </CCard>
                </CContainer >
            </div >
        )
    }
}

export default BusTransportForm
