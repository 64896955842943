import React, { lazy } from "react"
import {
    CAvatar,
    CContainer,
    CButton,
    CButtonGroup,
    CInputGroupText,
    CCard,
    CCardBody,
    CCardFooter,
    CCardHeader,
    CCol,
    CProgress,
    CRow,
    CTable,
    CTableBody,
    CTableDataCell,
    CTableHead,
    CTableHeaderCell,
    CTableRow,
    CModal,
    CModalHeader,
    CModalBody,
    CModalFooter,
    CModalTitle,
    CForm,
    CFormLabel,
    CFormInput,
    CFormTextarea,
    CFormCheck,
    CInputGroup,
    CFormSwitch,
    CSpinner
} from "@coreui/react"
//base on react select component
//other fetures pls read https://react-select.com/
import Select from "../../../containers/SelectOption";
//=================================================
import ButtonLoading from "../../../containers/ButtonLoading"
import StepperForm from "../../../containers/StepperForm"
import ImageUploading from "react-images-uploading";
import SnackbarAlert from "../../../containers/SnackbarAlert"
import AsteriskPoint from "../../../containers/AsteriskPoint";
import DateInput from "../../../containers/DateInput"
import TimeInput from "../../../containers/TimeInput"
import CIcon from "@coreui/icons-react"
import axios from "axios";
import { Component } from "react"
import {
    API_URL
} from "../../../settings"
import {
    getCurDate
} from "../../../lib/util"
import { cilStar } from '@coreui/icons';
import { Icon, IconButton } from "@material-ui/core";
import liff from '@line/liff';
import { ThemeConsumer } from "react-bootstrap/esm/ThemeProvider";
const queryString = require('query-string');

export class UpdateServiceBus extends Component {
    constructor(props) {
        super(props);
        this.state = {
            curDate: getCurDate(),
            isLoadded: false,
            name: '',
            userLineID: '',
            pictureUrl: '',
            starRating: 0,
            dateUsed: '',
            timeUsed: '',
            busUsed: '',
            feedbackMessage: '',
            isSetDatetimeUsed: false,
            isSummitedFeeback: false,
            optionsBusUsed: [],
            isBusAvailable: false,
            isUpdateFail: false
        }
    }

    componentDidMount = async () => {
        // this.lineRegister http://localhost:4030/api/update_service_bus?bus_id=18&line_id=AC&is_bus_available=true
        const parsed = queryString.parse(this.props.location.search);
        this.updateServiceBusStatus(parsed);
        this.setState({
            isLoadded: true
        })
    }

    updateServiceBusStatus = (parsed) => {
        console.log(parsed)
        if (parsed.bus_id && parsed.is_bus_available) { //&& this.state.userLineID) {
            let updateData = () => {
                axios({
                    method: "PUT",
                    url: API_URL + "update_service_bus?bus_id=" + parsed.bus_id + "&line_id=" + "ABC" + "&is_bus_available=" + parsed.is_bus_available
                })
                    .then((result) => {
                        let statusUpdate =  (parsed.is_bus_available === 'true');
                        if (result.data.success) {
                            if (statusUpdate) {
                                this.setState({
                                    isUpdateFail: false,
                                    isBusAvailable: statusUpdate
                                })
                            } else {
                                this.setState({
                                    isUpdateFail: false,
                                    isBusAvailable: statusUpdate
                                })
                            }
                        }
                        else {
                            this.setState({
                                isUpdateFail: true
                            })
                        }
                    }, (error) => {
                        this.setState({ isUpdateFail: true })
                        console.log(error)
                    }
                    )
            }
            updateData();

        } else {
            this.setState({ isUpdateFail: true })
        }
    }

    lineRegister = async () => {
        await liff.init({ liffId: '1657406254-7rgxeyBa' }).catch(err => { throw err });
        if (liff.isLoggedIn()) {
            let getProfile = await liff.getProfile();
            let getIDToken = await liff.getIDToken();
            console.log("---------userID-----------")
            console.log(getProfile.userId);
            console.log(getProfile.displayName);
            console.log(getProfile.pictureUrl);
            console.log("---------getIDToken-----------")
            console.log(getIDToken);
            this.setState({
                lineDisplayName: getProfile.displayName,
                userLineID: getProfile.userId,
                pictureUrl: getProfile.pictureUrl,
            });
        } else {
            liff.login();
        }
    }

    //change state field for general field form
    inputChange = (input) => (e) => {
        this.setState({
            [input]: e.target.value,
        });
    };

    //change state field for checkbox field form
    inputCheck = (input) => (e) => {
        this.setState({
            [input]: e.target.checked,
        })
    }


    handleSubmitForm = async (e) => {
        e.preventDefault();
        const {
            feedbackMessage,
            userLineID,
            starRating,
            curDate,
            dateUsed,
            timeUsed,
            busUsed,
            isSetDatetimeUsed
        } = this.state
        this.setState({
            isButtonLoaded: false
        })
        let dataBody = {
            "user_line_id": userLineID,
            "bus_id": busUsed,
            "feedback_message": feedbackMessage,
            "rating": starRating,
            "create_at": curDate,
        }
        if (isSetDatetimeUsed) {
            dataBody.time_used = timeUsed
            dataBody.date_used = dateUsed
        }
        axios({
            method: "POST",
            url: API_URL + "bus_feedback",
            headers: {
                "Content-Type": "application/json",
            },
            data: dataBody,
        })
            .then((result) => {
                this.setState({
                    isSummitedFeeback: true
                })
            }, (error) => {
                console.log(error)
            }
            )
    }

    render() {
        const {
            isSummitedFeeback,
            isLoadded,
            starRating,
            dateUsed,
            timeUsed,
            busUsed,
            feedbackMessage,
            isSetDatetimeUsed,
            optionsBusUsed,
            isBusAvailable,
            isUpdateFail
        } = this.state


        return (
            <>
                {isLoadded ?
                    (
                        <div
                            style={{
                                backgroundColor: '#41826e',
                                fontFamily: 'kanit',
                                paddingTop: 12,
                                paddingBottom: 12,
                            }
                            }
                            className="min-vh-100 d-flex flex-row"
                        >
                            <CContainer >
                                {/* <CRow>
                                        <CCol style={{ textAlign: 'center', marginBottom: 10 }}>
                                            <img height="100" src={require("../../../assets/icons/su-char-icon.png").default} />
                                        </CCol>
                                    </CRow> */}
                                <CCard
                                    style={{ paddingTop: 10, paddingBottom: 10 }}
                                >
                                    <CCardBody>
                                        <CRow>
                                            <CCol style={{ textAlign: 'center', marginTop: 20 }}>
                                                <img height="100" src={require("../../../assets/icons/su-char-icon.png").default} />
                                            </CCol>
                                        </CRow>
                                        <CRow>
                                            <CCol style={{ textAlign: 'center', marginTop: 10 }}>
                                                <h4>ตั้งค่าสถานะการให้บริการรถบัส</h4>
                                            </CCol>
                                        </CRow>

                                        {!isUpdateFail ?
                                            <>
                                                {isBusAvailable && isLoadded ?
                                                    <>
                                                        <CRow>
                                                            <CCol style={{ textAlign: 'center', marginTop: 20 }}>
                                                                <img height="100" src={require("../../../assets/icons/service-available.png").default} />
                                                            </CCol>
                                                        </CRow>

                                                        <CRow>
                                                            <CCol style={{ textAlign: 'center', marginTop: 10 }}>
                                                                <h6>พร้อมให้บริการ</h6>
                                                            </CCol>
                                                        </CRow>
                                                    </> :
                                                    <>
                                                        <CRow>
                                                            <CCol style={{ textAlign: 'center', marginTop: 20 }}>
                                                                <img height="100" src={require("../../../assets/icons/service-unavailable.png").default} />
                                                            </CCol>
                                                        </CRow>
                                                        <CRow>
                                                            <CCol style={{ textAlign: 'center', marginTop: 10 }}>
                                                                <h6>หยุดให้บริการ</h6>
                                                            </CCol>
                                                        </CRow>
                                                    </>
                                                }</>
                                            :
                                            <>
                                                <CRow>
                                                    <CCol style={{ textAlign: 'center', marginTop: 20 }}>
                                                        <img height="100" src={require("../../../assets/icons/failure-img.png").default} />
                                                    </CCol>
                                                </CRow>
                                                <CRow>
                                                    <CCol style={{ textAlign: 'center', marginTop: 10 }}>
                                                        <h6>ไม่สามารถตั้งค่าสถานะการให้บริการได้</h6>
                                                    </CCol>
                                                </CRow>
                                            </>
                                        }

                                    </CCardBody>
                                </CCard>
                            </CContainer >

                        </div>
                    ) :
                    (
                        <div
                            style={{ backgroundColor: '#41826e', fontFamily: 'kanit', paddingTop: 20 }}
                            className="min-vh-100 d-flex flex-row align-items-center" >
                            <CContainer>
                                <CRow className="justify-content-center">
                                    <CCol style={{ textAlign: 'center' }}>
                                        <CSpinner color="light" size="xxl" />
                                    </CCol>
                                </CRow>
                            </CContainer>
                        </div>
                    )
                }
            </>
        )
    }
}

export default UpdateServiceBus
