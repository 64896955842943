import React, { lazy } from "react"
import {
    CAvatar,
    CContainer,
    CButton,
    CButtonGroup,
    CInputGroupText,
    CCard,
    CCardBody,
    CCardFooter,
    CCardHeader,
    CCol,
    CProgress,
    CRow,
    CTable,
    CTableBody,
    CTableDataCell,
    CTableHead,
    CTableHeaderCell,
    CTableRow,
    CModal,
    CModalHeader,
    CModalBody,
    CModalFooter,
    CModalTitle,
    CForm,
    CFormLabel,
    CFormInput,
    CFormTextarea,
    CFormCheck,
    CInputGroup,
    CFormSwitch,
    CSpinner
} from "@coreui/react"
//base on react select component
//other fetures pls read https://react-select.com/
import Select from "../../../containers/SelectOption";
//=================================================
import StepperForm from "../../../containers/StepperForm"
import ImageUploading from "react-images-uploading";
import SnackbarAlert from "../../../containers/SnackbarAlert"
import AsteriskPoint from "../../../containers/AsteriskPoint"
import DateInput from "../../../containers/DateInput"
import CIcon from "@coreui/icons-react"
import { cibAddthis, cilDelete, cilTrash } from '@coreui/icons';
import axios from "axios";
import { Component } from "react"
import {
    API_URL
} from "../../../settings"
import {
    getCurDate
} from "../../../lib/util"
import { Icon } from "@material-ui/core";
import liff from '@line/liff';


const apiUrl = 'https://my.ugravity.net/api/';

export class ServiceBusAlert extends Component {
    constructor(props) {
        super(props);
        this.state = {
            curDate: getCurDate(),
            isLoadded: false,
            name: '',
            userLineID: '',
            pictureUrl: '',
            alarmList: [],
            idCardSelected: ''
        }
    }

    componentDidMount = async () => {
        console.log(this.props)
        this.setState({
            alarmList: this.props.alarmList
        })
    }

    viewTimeAlarm = (time) => {
        let exTime = time.split(':')
        return exTime[0] + ':' + exTime[1]
    }

    mappingThaiDay = (dof) => {
        switch (dof) {
            case 0:
                return 'อา'
            case 1:
                return 'จ'
            case 2:
                return 'อ'
            case 3:
                return 'พ'
            case 4:
                return 'พฤ'
            case 5:
                return 'ศ'
            case 6:
                return 'ส'
            default:
                break;
        }
    }

    covertDigitToTenDigitString = (number) => {
        return number % 10 == number ? '0' + number.toString() : number
    }

    viewDayAlarm = (data) => {
        let dayList = data.day_alarm
        if (dayList.length <= 0) {
            const curDate = new Date();
            let dateAlarm = new Date(
                curDate.getFullYear() + '-'
                + this.covertDigitToTenDigitString(curDate.getMonth() + 1) + '-'
                + this.covertDigitToTenDigitString(curDate.getDate()) + ' '
                + data.time_alarm
            )
            if (dateAlarm > curDate) {
                return 'วันนี้'
            } else {
                return 'พรุ่งนี้'
            }
        }
        if (dayList.length == 7) {
            return 'ทุกวัน'
        }
        let dayStr = ''
        for (let i = 0; i < dayList.length; i++) {
            dayStr += this.mappingThaiDay(dayList[i].day_of_week)
            if (i != dayList.length - 1) {
                dayStr += ' '
            }
        }
        return dayStr
    }

    onChangeSwitchEnable = (dataAlarm, index) => {
        const curDate = this.state.curDate
        let {
            alarmList
        } = this.state
        dataAlarm.alarm_enable = !dataAlarm.alarm_enable
        axios({
            method: "PUT",
            url: API_URL + "bus_services_alert/" + dataAlarm.id,
            headers: {
                "Content-Type": "application/json",
            },
            data: {
                "alarm_enable": dataAlarm.alarm_enable,
                "update_at": curDate,
            },
        })
            .then((result) => {
                alarmList[index] = dataAlarm
                this.setState({
                    alarmList: alarmList
                })
            }, (error) => {
                console.log(error)
            }
            )
    }

    handleButtonPress = () => {
        this.buttonPressTimer = setTimeout(() => alert('long press activated'), 500);
    }

    handleButtonRelease = () => {
        clearTimeout(this.buttonPressTimer);
    }

    handleRemove = async (alarmID) => {
        await axios.delete(API_URL + "bus_services_alert/" + alarmID);
        this.props.reloadData()
    }

    render() {
        const {
            alarmList,
            alarmBeforMin
        } = this.state


        return (
            <div
                style={{
                    backgroundColor: '#41826e',
                    fontFamily: 'kanit',
                    paddingTop: 12,
                    paddingBottom: 12
                }
                }
                className="min-vh-100 d-flex flex-row"
            >
                <CContainer >
                    <CCard
                    >
                        <CCardBody
                            style={{ minHeight: window.innerHeight * 0.6 }}
                        >
                            <CRow>
                                <CCol style={{ textAlign: 'center', marginBottom: 10 }}>
                                    <img height="100" src={require("../../../assets/icons/su-char-icon.png").default} />
                                </CCol>
                            </CRow>
                            <CRow>
                                <CCol style={{ textAlign: 'center' }}>
                                    <h4>ตั้งค่าแจ้งเตือน รถบัสรับ-ส่ง</h4>
                                </CCol>
                            </CRow>
                            <CRow>
                                <CCol style={{ textAlign: 'right' }}>
                                    <button className="btn" style={{ padding: 0 }} onClick={() => { this.props.onAddAlarm() }}>
                                        {/* <CIcon icon={cibAddthis} size="lg" /> */}
                                        <img height="40" src={require("../../../assets/icons/icon-add-ios.png").default} />
                                    </button>
                                </CCol>
                            </CRow>

                            {alarmList.map((data, index) =>
                                <CCard key={index} className={"shadow-sm bg-body rounded " + (data.alarm_enable ? 'card-active' : 'card-inactive')}
                                    style={{marginTop: 10, marginLeft: -16, marginRight: -16 }}
                                    onClick={() => this.props.onEditSelected(data)}
                                >
                                    <CCardBody style={{ paddingTop:10,paddingBottom:10/*, background: 'whitesmoke' */}}>
                                        <CRow>
                                            <CCol xs={10} style={{ textAlign: 'left' }}>
                                                <CFormLabel style={{ fontSize: 14 }}>{data.alarm_name}</CFormLabel>
                                            </CCol>
                                            <CCol xs={2} onClick={e => { e.stopPropagation(); }} style={{ textAlign: 'right' }}>
                                                {/* <CFormSwitch size="xl" id="formSwitchCheckDefaultLg" /> */}
                                                <CFormSwitch
                                                    size="xl"
                                                    checked={data.alarm_enable}
                                                    onChange={() =>
                                                        this.onChangeSwitchEnable(data)
                                                    }
                                                />
                                            </CCol>
                                        </CRow>
                                        <CRow>
                                            <CCol xs={6} style={{ textAlign: 'left' }}>
                                                <h4>{this.viewTimeAlarm(data.time_alarm)}</h4>
                                            </CCol>
                                            <CCol xs={6} style={{ textAlign: 'left' }} >
                                                <CRow >
                                                    <CCol>
                                                        <CFormLabel >{this.viewDayAlarm(data)}</CFormLabel>
                                                    </CCol>
                                                </CRow>
                                            </CCol>
                                        </CRow>
                                        <CRow>
                                            <CCol xs={6} style={{ textAlign: 'left' }}>
                                                <CFormLabel>{data.station_name}</CFormLabel>
                                            </CCol>
                                            <CCol xs={6} style={{ textAlign: 'right' }} >
                                                <CRow >
                                                    <CCol xs={8}>
                                                    </CCol>
                                                    <CCol xs={4} onClick={e => { e.stopPropagation(); }} style={{ textAlign: 'center' }}>
                                                        <button className="btn" style={{ padding: 0 }} onClick={() => this.handleRemove(data.id)}>
                                                            <img height="20" src={require("../../../assets/icons/icon_trash.png").default} />
                                                        </button>
                                                    </CCol>
                                                </CRow>
                                            </CCol>
                                        </CRow>
                                    </CCardBody>
                                </CCard>
                            )}

                            {/* {alarmList.length <= 0 &&
                                // <CRow style={{verticalAlign:'center',minHeight: window.innerHeight * 0.8}}>
                                    <div style={{textAlign:'center',lineHeight:10}}>
                                        <CFormLabel style={{ color: 'grey' }}>{"ไม่มีรายการแจ้งเตือน"}</CFormLabel>
                                    </div>
                            } */}

                            {/* <CCard className={"shadow-sm bg-body rounded card-active"}
                                style={{ marginTop: 10 }}
                                onClick={() => this.props.onEditSelected('')}
                            >
                                <CCardBody>
                                    <CRow>
                                        <CCol xs={6} style={{ textAlign: 'left' }}>
                                            <CFormLabel style={{ fontSize: 14 }}></CFormLabel>
                                        </CCol>
                                    </CRow>
                                    <CRow>
                                        <CCol xs={6} style={{ textAlign: 'left' }}>
                                            <h4>{this.viewTimeAlarm('08:57')}</h4>
                                        </CCol>
                                        <CCol xs={6} style={{ textAlign: 'right' }} >
                                            <CRow >
                                                <CCol xs={8}>
                                                    <CFormLabel >{this.viewDayAlarm([])}</CFormLabel>
                                                </CCol>
                                                <CCol xs={4} onClick={e => { e.stopPropagation(); }} style={{ textAlign: 'right' }}>
                                                    <CFormSwitch
                                                        size="xl"
                                                        checked={true}
                                                        onChange={() =>
                                                            this.onChangeSwitchEnable(true)
                                                        }
                                                    />
                                                </CCol>
                                            </CRow>
                                        </CCol>
                                    </CRow>
                                    <CRow>
                                        <CCol xs={6} style={{ textAlign: 'left' }}>
                                            <CFormLabel>{'สถานีนครปฐม'}</CFormLabel>
                                        </CCol>
                                    </CRow>
                                </CCardBody>
                            </CCard> */}

                        </CCardBody>
                    </CCard>
                </CContainer>

            </div >
        )
    }
}

export default ServiceBusAlert
