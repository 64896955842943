function isnull(value, newvalue) {
    return value == undefined ? newvalue : value;
}
function isnullandBlank(value, newvalue) {
    return value == null || value == '' ? newvalue : value;
}
function isnullShowBlank(value) {
    return value == null || value == '' ? '&nbsp' : value;
}
function isZero(value, newvalue) {
    return value == '0' ? newvalue : value;
}
function addObject(parent, type, classname, id, text = '', Style = '') {
    var parentobj = document.getElementById(parent);
    var childobj = document.createElement(type);
    childobj.id = id;
    childobj.className = classname;
    childobj.style.cssText = Style;
    if (type == 'col') {
        var Text = document.createElement('lable');
        Text.innerHTML = text;
        childobj.appendChild(Text);
    } else {
        childobj.innerHTML = text;
    }
    parentobj.appendChild(childobj);
}
function addcell(row, text = '', style = '') {
    var cell = row.insertCell();
    cell.innerHTML = text;
    cell.style.cssText = style;
}
function addcellWithrowSpan(row, rowspan, text = '', style = '') {
    var cell = row.insertCell();
    cell.rowSpan = rowspan;
    cell.innerHTML = text;
    cell.style.cssText = style;
}
function addcellWithcolSpan(row, colspan, text = '', style = '') {
    var cell = row.insertCell();
    cell.colSpan = colspan;
    cell.innerHTML = text;
    cell.style.cssText = style;
}
function convertThaiNumber(number) {
    number = number.toString();
    number = number.replace(/0/g, "๐");
    number = number.replace(/1/g, "๑");
    number = number.replace(/2/g, "๒");
    number = number.replace(/3/g, "๓");
    number = number.replace(/4/g, "๔");
    number = number.replace(/5/g, "๕");
    number = number.replace(/6/g, "๖");
    number = number.replace(/7/g, "๗");
    number = number.replace(/8/g, "๘");
    number = number.replace(/9/g, "๙");
    return number;
}
function convertThaiDate(date) {
    let dte = new Date(date);
    var thday = new Array("อาทิตย์", "จันทร์",
        "อังคาร", "พุธ", "พฤหัส", "ศุกร์", "เสาร์");
    var thmonth = new Array("มกราคม", "กุมภาพันธ์", "มีนาคม",
        "เมษายน", "พฤษภาคม", "มิถุนายน", "กรกฎาคม", "สิงหาคม", "กันยายน",
        "ตุลาคม", "พฤศจิกายน", "ธันวาคม");
    return "วัน" + thday[dte.getDay()] + "ที่ " + dte.getDate() + " " +
        thmonth[dte.getMonth()] + " " + (0 + dte.getFullYear() + 543);
}
function convertshortThaiDate(date) {
    let dte = new Date(date);
    var thday = new Array("อาทิตย์", "จันทร์",
        "อังคาร", "พุธ", "พฤหัส", "ศุกร์", "เสาร์");
    var thmonth = new Array("มกราคม", "กุมภาพันธ์", "มีนาคม",
        "เมษายน", "พฤษภาคม", "มิถุนายน", "กรกฎาคม", "สิงหาคม", "กันยายน",
        "ตุลาคม", "พฤศจิกายน", "ธันวาคม");
    return dte.getDate() + " " +
        thmonth[dte.getMonth()] + " " + (0 + dte.getFullYear() + 543);
}
function getCurDate() {
    const THEMP_DATE = new Date();
    const CUR_DATE =
        THEMP_DATE.getFullYear() +
        "-" +
        (THEMP_DATE.getMonth() + 1) +
        "-" +
        THEMP_DATE.getDate() +
        " " +
        THEMP_DATE.getHours() +
        ":" +
        THEMP_DATE.getMinutes() +
        ":" +
        THEMP_DATE.getSeconds();
    return CUR_DATE
}
function getStringDate(THEMP_DATE) {
    const DATE_STR =
        THEMP_DATE.getFullYear() +
        "-" +
        (THEMP_DATE.getMonth() + 1) +
        "-" +
        THEMP_DATE.getDate() +
        " " +
        THEMP_DATE.getHours() +
        ":" +
        THEMP_DATE.getMinutes() +
        ":" +
        THEMP_DATE.getSeconds();
    return DATE_STR
}

function getMonthThaiName(date) {
    let dte = new Date(date);
    var thmonth = new Array("มกราคม", "กุมภาพันธ์", "มีนาคม",
        "เมษายน", "พฤษภาคม", "มิถุนายน", "กรกฎาคม", "สิงหาคม", "กันยายน",
        "ตุลาคม", "พฤศจิกายน", "ธันวาคม");
    return thmonth[dte.getMonth()];
}
function getYearThai(date) {
    let dte = new Date(date);
    return (0 + dte.getFullYear() + 543)
}

function GetQueryString(name) {
    var after = window.location.hash.split("?")[1];
    if (after) {
        var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
        var r = after.match(reg);
        if (r != null) {
            return decodeURIComponent(r[2]);
        }
        else {
            return null;
        }
    }
}

function escapeRegExp(value) {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
}

export {
    addObject,
    addcell,
    addcellWithrowSpan,
    addcellWithcolSpan,
    convertThaiNumber,
    convertThaiDate,
    convertshortThaiDate,
    getCurDate,
    getMonthThaiName,
    getYearThai,
    isnull,
    isnullandBlank,
    isnullShowBlank,
    isZero,
    GetQueryString,
    escapeRegExp,
    getStringDate
};
