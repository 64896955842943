import React, { lazy } from "react"
import {
    CAvatar,
    CContainer,
    CButton,
    CButtonGroup,
    CInputGroupText,
    CCard,
    CCardBody,
    CCardFooter,
    CCardHeader,
    CCol,
    CProgress,
    CRow,
    CTable,
    CTableBody,
    CTableDataCell,
    CTableHead,
    CTableHeaderCell,
    CTableRow,
    CModal,
    CModalHeader,
    CModalBody,
    CModalFooter,
    CModalTitle,
    CForm,
    CFormLabel,
    CFormInput,
    CFormTextarea,
    CFormCheck,
    CInputGroup,
    CFormSwitch,
    CSpinner
} from "@coreui/react"
//base on react select component
//other fetures pls read https://react-select.com/
import Select from "../../../containers/SelectOption";
//=================================================
import StepperForm from "../../../containers/StepperForm"
import ImageUploading from "react-images-uploading";
import SnackbarAlert from "../../../containers/SnackbarAlert"
import AsteriskPoint from "../../../containers/AsteriskPoint";
import DateInput from "../../../containers/DateInput"
import CIcon from "@coreui/icons-react"
import ConfirmDialog from '../../../containers/ConfirmDialog'
import { cibAddthis, cilDelete, cilTrash } from '@coreui/icons';
import axios from "axios";
import { Component } from "react"
import {
    API_URL
} from "../../../settings"
import {
    getCurDate
} from "../../../lib/util"
import liff from '@line/liff';
import { Icon, IconButton, Button } from "@material-ui/core";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';

export class BusTransportList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            curDate: getCurDate(),
            isLoadded: false,
            isShowConfirmDialog: false,
            name: '',
            userLineID: '',
            pictureUrl: '',
            idDelete: '',
            listData: [],
        }
    }

    componentDidMount = async () => {
        console.log(this.props)
        this.setState({
            listData: this.props.listData
        })
    }

    handleRemove = async () => {
        await axios.delete(API_URL + "bus_driver/" + this.state.idDelete);
        this.props.reloadData()
    }

    handleSearchChange = () => (e) => {
        if (e.keyCode == 13) {
            var searchValue = e.target.value;
            this.props.reloadData(searchValue)
        }
    };

    onCloseConfrimDialog = () => {
        this.setState({
            isShowConfirmDialog: false
        })
    }


    render() {
        const {
            listData,
            alarmBeforMin,
            isShowConfirmDialog
        } = this.state

        return (
            <div
                style={{
                    backgroundColor: '#41826e',
                    fontFamily: 'kanit',
                    paddingTop: 12,
                    paddingBottom: 12,
                }
                }
                className="min-vh-100 d-flex flex-row"
            >
                <CContainer >
                    <CCard
                    >
                        <CCardBody
                            style={{ minHeight: window.innerHeight * 0.8 }}
                        >
                            <ConfirmDialog
                                show={isShowConfirmDialog}
                                title={"กำลังลบข้อมูล"}
                                content={"คุณยืนยันที่จะลบข้อมูล ใช่หรือไม่?"}
                                onConfirm={this.handleRemove}
                                onClose={this.onCloseConfrimDialog} />
                            <CRow>
                                <CCol style={{ textAlign: 'center', marginBottom: 10 }}>
                                    <img height="80" src={require("../../../assets/icons/su-char-icon.png").default} />
                                </CCol>
                            </CRow>
                            <CRow>
                                <CCol  style={{ textAlign: 'center', paddingTop: 10 }}>
                                    <h4>ข้อมูลรถบัส</h4>
                                </CCol>
                            </CRow>
                            <CRow>
                                <CCol xs="5" style={{ textAlign: 'left', marginTop: 10 }}>
                                    <CFormInput
                                        type="text"
                                        id={"maxSeat"}
                                        required={true}
                                        maxLength={256}
                                        onKeyDown={this.handleSearchChange()}
                                        // onChange={this.inputChange("maxSeat")}
                                        // value={maxSeat}
                                        placeholder="ค้นหา..."
                                    />
                                </CCol>
                                <CCol xs="7" style={{ textAlign: 'right', marginTop: 10 }}>
                                    <CButton
                                        type="reset"
                                        variant="outline"
                                        color="primary"
                                        onClick={() => { this.props.onAddAlarm() }}
                                    ><AddIcon />เพิ่มข้อมูล</CButton>
                                </CCol>
                            </CRow>
                            {listData.map((data, index) =>
                                <CCard
                                    key={index}
                                    style={{ marginTop: 10 }}
                                // onClick={() => this.props.onEditSelected(data)}
                                >
                                    <CCardBody>
                                        {/* <CRow>
                                            <CCol style={{ textAlign: 'left' }}>
                                                <CFormLabel style={{ fontSize: 10, margin: 0 }}>{'ชื่อรถ'}</CFormLabel>
                                            </CCol>
                                            <CCol style={{ textAlign: 'right' }}>
                                                <IconButton style={{ padding: 0 }} color="inherit" component="label" onClick={() => this.props.onEditSelected(data)}>
                                                    <EditIcon fontSize="small" color="success" />
                                                </IconButton>
                                            </CCol>
                                        </CRow> */}
                                        <CRow>
                                            <CCol style={{ textAlign: 'left' }}>
                                                <CFormLabel style={{ margin: 0 }}>{data.bus_name}</CFormLabel>
                                            </CCol>
                                            <CCol style={{ textAlign: 'right' }}>
                                                <IconButton style={{ padding: 0 }} color="inherit" component="label" onClick={() => this.props.onEditSelected(data)}>
                                                    <EditIcon fontSize="small" color="success" />
                                                </IconButton>
                                            </CCol>
                                        </CRow>
                                        <CRow>
                                            <CCol style={{ textAlign: 'left' }}>
                                                <CFormLabel style={{ fontSize: 10, margin: 0 }}>{'ความจุผู้โดยสาร'}</CFormLabel>
                                            </CCol>

                                        </CRow>
                                        <CRow>
                                            <CCol style={{ textAlign: 'left' }}>
                                                <CFormLabel style={{ margin: 0 }}>{data.max_seat + ' คน'}</CFormLabel>
                                            </CCol>
                                            <CCol style={{ textAlign: 'right' }}>
                                            </CCol>
                                        </CRow>

                                        <CRow>
                                            <CCol style={{ textAlign: 'left' }}>
                                                <CFormLabel style={{ fontSize: 10, margin: 0 }}>{'พนักงานขับรถ'}</CFormLabel>
                                            </CCol>

                                        </CRow>
                                        <CRow>
                                            <CCol style={{ textAlign: 'left' }}>
                                                <CFormLabel style={{ margin: 0 }}>{data.driver_fname+ ' '+ data.driver_lname}</CFormLabel>
                                            </CCol>
                                            <CCol style={{ textAlign: 'right' }}>
                                                <IconButton style={{ padding: 0 }} component="label" onClick={() => this.setState({ isShowConfirmDialog: true, idDelete: data.id })}>
                                                    <DeleteIcon fontSize="small" color="error" />
                                                </IconButton>
                                            </CCol>
                                        </CRow>
                                    </CCardBody>
                                </CCard>
                            )}

                        </CCardBody>
                    </CCard>
                </CContainer>

            </div >
        )
    }
}

export default BusTransportList
