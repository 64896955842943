import React from 'react';
const AsteriskPoint = (props) => {
    var show = props.show == undefined ? true : props.show
    return (
        <>
            {show &&
                <span style={{ marginTop: 20, color: 'red' }}> *</span>
            }
        </>
    )
}
export default AsteriskPoint
