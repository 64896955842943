import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { CButton } from "@coreui/react";
const ConfirmDialog = (props) => {
    //create at 02/09/64 by dmoss
    var title = props.title ? props.title : "กำลังออกจากหน้านี้"
    var content = props.content ? props.content : "คุณต้องการออกโดยไม่บันทึก ใช่หรือไม่?"
    var show = props.show
    let handleClose = props.onClose
    let handleConfirm = props.onConfirm
    return (
        <>
            <Dialog
                open={show}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {content}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <CButton onClick={() => handleClose()} color="info">
                        ยกเลิก
                    </CButton>
                    <CButton onClick={() => handleConfirm()} color="danger" autoFocus>
                        ยืนยัน
                    </CButton>
                </DialogActions>
            </Dialog>
        </>
    )

}
export default ConfirmDialog
