import React, { lazy } from "react"
import {
    CAvatar,
    CContainer,
    CButton,
    CButtonGroup,
    CInputGroupText,
    CCard,
    CCardBody,
    CCardFooter,
    CCardHeader,
    CCol,
    CProgress,
    CRow,
    CTable,
    CTableBody,
    CTableDataCell,
    CTableHead,
    CTableHeaderCell,
    CTableRow,
    CModal,
    CModalHeader,
    CModalBody,
    CModalFooter,
    CModalTitle,
    CForm,
    CFormLabel,
    CFormInput,
    CFormTextarea,
    CFormCheck,
    CInputGroup,
    CFormSwitch,
    CSpinner
} from "@coreui/react"
//base on react select component
//other fetures pls read https://react-select.com/
import Select from "../../../containers/SelectOption";
//=================================================
import ButtonLoading from "../../../containers/ButtonLoading"
import StepperForm from "../../../containers/StepperForm"
import ImageUploading from "react-images-uploading";
import SnackbarAlert from "../../../containers/SnackbarAlert"
import AsteriskPoint from "../../../containers/AsteriskPoint";
import DateInput from "../../../containers/DateInput"
import TimeInput from "../../../containers/TimeInput"
import CIcon from "@coreui/icons-react"
import axios from "axios";
import { Component } from "react"
import {
    API_URL
} from "../../../settings"
import {
    getCurDate
} from "../../../lib/util"
import { cilStar } from '@coreui/icons';
import { Icon, IconButton } from "@material-ui/core";
import liff from '@line/liff';
import { ThemeConsumer } from "react-bootstrap/esm/ThemeProvider";

export class AdminRegister extends Component {
    constructor(props) {
        super(props);
        this.state = {
            curDate: getCurDate(),
            isLoadded: false,
            name: '',
            userLineID: 'test',
            pictureUrl: '',
            starRating: 0,
            isShowAlert: false,
            colorAlert: "",
            messageAlert: "",
            fullName:"",
            dateUsed: '',
            timeUsed: '',
            driverID: '',
            feedbackMessage: '',
            isSetDatetimeUsed: false,
            isSummitedFeeback: false,
            optionsDriver: [],
            lineDisplayName: '',
            pictureUrl: '',
            isLoggedIn: false,
            username: '',
            password: '',
            messageLastPage : 'ลงทะเบียนสำเร็จ'
        }
    }

    componentDidMount = async () => {
        this.lineRegister();
        this.setState({
            isLoadded: true
        })
    }

    lineRegister = async () => {
        await liff.init({ liffId: '1657406254-2eY65Q3A' }).catch(err => { throw err });
        if (liff.isLoggedIn()) {
            let getProfile = await liff.getProfile();
            let getIDToken = await liff.getIDToken();
            console.log("---------userID-----------")
            console.log(getProfile.userId);
            console.log(getProfile.displayName);
            console.log(getProfile.pictureUrl);
            console.log("---------getIDToken-----------")
            console.log(getIDToken);
            this.setState({
                lineDisplayName: getProfile.displayName,
                userLineID: getProfile.userId,
                pictureUrl: getProfile.pictureUrl,
            });
        } else {
            liff.login();
        }
    }


    handleCloseAlert = () => {
        this.setState({ isShowAlert: false })
    }

    getNearbyBus = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                console.log(position.coords.latitude)
                console.log(position.coords.longitude)
                let getData = async () => {
                    let results = await axios.get(API_URL + 'getBusNearby?cur_lat=' + position.coords.latitude + '&cur_lng=' + position.coords.longitude)
                    if (results.data.length > 0) {
                        this.setState({
                            driverID: results.data[0].bus_id
                        })
                    }
                }
                getData();
            });
        } else {
            console.log("Geolocation is not supported by this browser.");
        }
    }


    getBusTransport = async () => {
        try {
            let results = await axios.get(API_URL + 'bus_transport')
            let arrOptions = results.data.map((data) => ({
                label: data['bus_name'],
                value: data['id'],
            }))
            arrOptions.unshift({
                label: 'ไม่ระบุ',
                value: ''
            })
            this.setState({
                optionsDriver: arrOptions
            })
        }
        catch (e) {
            console.log(e)
        }

    }

    //change state field for single select field form
    handleChangeSingleOption = (input) => (e) => {
        if (e) {
            this.setState({
                [input]: e.value,
            })
        } else {
            this.setState({
                [input]: "",
            })
        }
    }

    //change state field for general field form
    inputChange = (input) => (e) => {
        this.setState({
            [input]: e.target.value,
        });
    };


    //change state field for checkbox field form
    inputCheck = (input) => (e) => {
        this.setState({
            [input]: e.target.checked,
        })
    }

    //change date input 
    inputChangeDate = (input) => (e) => {
        this.setState({
            [input]: e.format("YYYY-MM-DD HH:mm"),
        });
    };

    handleChangePage = (pageView) => {
        this.setState({
            pageView: pageView,
            isLoadded: true
        })
    }

    onEditSelected = (dataEdit) => {
        this.setState({
            dataEditSelected: dataEdit,
            pageView: 'page-form',
            isLoadded: true
        })
    }

    handleSubmitForm = async (e) => {
        e.preventDefault();
        const {
            userLineID,
            driverID,
            curDate,
            isLoggedIn,
            username,
            password
        } = this.state
        this.setState({
            isButtonLoaded: false
        })
        if (isLoggedIn) {
            axios({
                method: "POST",
                url: API_URL + "updateLineBusUid" ,
                headers: {
                    "Content-Type": "application/json",
                },
                data: {
                    "username":username,
                    "line_id":userLineID
                },
            })
                .then((result) => {
                    this.setState({
                        isSummitedFeeback: true,
                        messageLastPage: "ลงทะเบียนไลน์สำเร็จ!"
                    })
                }, (error) => {
                    console.log(error)
                }
                )
        } else {
            axios({
                method: "POST",
                url: API_URL + "auth/signin",
                headers: {
                    "Content-Type": "application/json",
                },
                data: {
                    "username": username,
                    "password": password
                },
            })
                .then((result) => {
                    if(result.data.roles[0]=="ROLE_ADMINISTRATOR" ){
                        this.setState({
                            isLoggedIn: true,
                            fullName:result.data.fullname
                        })
                    }else{
                        this.setState({
                            isSummitedFeeback: true,
                            messageLastPage: "ชื่อผู้ใช้ของคุณไม่ได้รับอนุญาต!"
                        })
                    }
                }, (error) => {
                    this.setState({
                        isShowAlert: true,
                        colorAlert: "error",
                        messageAlert: "ชื่อผู้ใช้หรือรหัสผ่านไม่ถูกต้อง!"
                    })                }
                )
        }
    }

    render() {
        const {
            isSummitedFeeback,
            isLoadded,
            isShowAlert,
            colorAlert,
            messageAlert,
            starRating,
            dateUsed,
            timeUsed,
            driverID,
            feedbackMessage,
            isSetDatetimeUsed,
            optionsDriver,
            lineDisplayName,
            pictureUrl,
            isLoggedIn,
            fullName,
            username,
            password,
            messageLastPage
        } = this.state

        // const optionsDriver = [
        //     { label: "ไม่ระบุ", value: "" },
        //     { label: "รถมายเลข 1", value: 1 },
        //     { label: "รถมายเลข 2", value: 2 },
        //     { label: "รถมายเลข 3", value: 3 },
        // ]

        function GetPageView(props) {
            switch (props.pageView) {
                case 'page-list':
                    return (<ServiceBusAlertList {...props} />)

                case 'page-form':
                    return (<ServiceBusAlertEdit {...props} />)

                default:
                    return '';
            }
        }

        function StarIcon(props) {
            if (!props.isSelected) {
                return (<img height="30" src={require("../../../assets/icons/icon-star.png").default} />)
            } else {
                return (<img height="30" src={require("../../../assets/icons/icon-star-fill.png").default} />)
            }
        }

        return (
            <>
                {isLoadded ?
                    (
                        <div
                            style={{
                                backgroundColor: '#41826e',
                                fontFamily: 'kanit',
                                paddingTop: 12,
                                paddingBottom: 12,
                            }
                            }
                            className="min-vh-100 d-flex flex-row"
                        >
                            <SnackbarAlert isShowAlert={isShowAlert} colorAlert={colorAlert} messageAlert={messageAlert} onClose={this.handleCloseAlert} />

                            {!isSummitedFeeback ?

                                <CContainer >
                                    <CCard
                                    >
                                        <CForm onSubmit={this.handleSubmitForm}>

                                            <CCardBody
                                                style={{ minHeight: window.innerHeight * 0.3 }}
                                            >
                                                <CRow>
                                                    <CCol style={{ textAlign: 'center', marginBottom: 10 }}>
                                                        <img height="80" src={require("../../../assets/icons/su-char-icon.png").default} />
                                                    </CCol>
                                                </CRow>
                                                <CRow>
                                                    <CCol style={{ textAlign: 'center' }}>
                                                        <h4>ลงทะเบียนไลน์ผู้ดูแลระบบ(Admin)</h4>
                                                    </CCol>
                                                </CRow>

                                                {isLoggedIn ?
                                                    <>
                                                        <CRow>
                                                            <CCol style={{ textAlign: 'center', marginTop: 10 }}>
                                                                <img height="120" src={pictureUrl} style={{ borderRadius: '100%' }} />
                                                            </CCol>
                                                        </CRow>

                                                        <CRow>
                                                            <CCol style={{ marginTop: 15 }}>
                                                                <CFormLabel>
                                                                    {"ไลน์ปัจจุบัน"}
                                                                </CFormLabel>
                                                            </CCol>
                                                        </CRow>
                                                        <CRow>
                                                            <CCol style={{ marginTop: 0 }}>
                                                                <CFormInput
                                                                    style={{ backgroundColor: 'white' }}
                                                                    type="text"
                                                                    id={"lineDisplayName"}
                                                                    placeholder=""
                                                                    value={lineDisplayName}
                                                                    readOnly
                                                                />
                                                            </CCol>
                                                        </CRow>

                                                        <CRow>
                                                            <CCol style={{ marginTop: 15 }}>
                                                                <CFormLabel>
                                                                    {"ชื่อ-สกุล"}
                                                                </CFormLabel>
                                                            </CCol>
                                                        </CRow>
                                                        <CRow>
                                                            <CCol style={{ textAlign: 'center', marginTop: 0 }}>
                                                                    <CFormInput
                                                                        autoComplete="fullName"
                                                                        onChange={this.inputChange("fullName")}
                                                                        value={fullName}
                                                                        required
                                                                    />
                                                            </CCol>
                                                        </CRow>
                                                    </> :
                                                    <>
                                                        <CRow>
                                                            <CCol style={{ textAlign: 'center', marginTop: 20 }}>
                                                                <CInputGroup className="mb-3">
                                                                    <CInputGroupText>
                                                                        <CIcon name="cil-user" />
                                                                    </CInputGroupText>
                                                                    <CFormInput
                                                                        placeholder="ชื่อผู้ใช้"
                                                                        autoComplete="username"
                                                                        onChange={this.inputChange("username")}
                                                                        value={username}
                                                                        required
                                                                    />
                                                                </CInputGroup>
                                                            </CCol>
                                                        </CRow>
                                                        <CRow>
                                                            <CCol style={{ textAlign: 'center', marginTop: 10 }}>
                                                                <CInputGroup className="mb-4">
                                                                    <CInputGroupText>
                                                                        <CIcon name="cil-lock-locked" />
                                                                    </CInputGroupText>
                                                                    <CFormInput
                                                                        type="password"
                                                                        placeholder="รหัสผ่าน"
                                                                        autoComplete="current-password"
                                                                        onChange={this.inputChange("password")}
                                                                        value={password}
                                                                        required
                                                                    />
                                                                </CInputGroup>
                                                            </CCol>
                                                        </CRow>
                                                    </>
                                                }
                                            </CCardBody>
                                            <CCardFooter>
                                                <CRow>
                                                    <CCol
                                                        className="d-grid gap-2"
                                                        style={{ textAlign: 'center', paddingTop: 10, paddingBottom: 10 }}
                                                    >
                                                        <ButtonLoading
                                                            // isLoaded={isButtonLoaded}
                                                            variant="outline"
                                                            color="success"
                                                            type="submit"
                                                        >บันทึก</ButtonLoading>
                                                    </CCol>
                                                </CRow>
                                            </CCardFooter>
                                        </CForm>
                                    </CCard>
                                </CContainer >


                                :
                                <CContainer >
                                    {/* <CRow>
                                        <CCol style={{ textAlign: 'center', marginBottom: 10 }}>
                                            <img height="100" src={require("../../../assets/icons/su-char-icon.png").default} />
                                        </CCol>
                                    </CRow> */}
                                    <CCard
                                        style={{ paddingTop: 10, paddingBottom: 10 }}
                                    >
                                        <CCardBody>
                                            <CRow>
                                                <CCol style={{ textAlign: 'center', marginTop: 20 }}>
                                                    <img height="100" src={require("../../../assets/icons/su-char-icon.png").default} />
                                                </CCol>
                                            </CRow>
                                            <CRow>
                                                <CCol style={{ textAlign: 'center', marginTop: 20 }}>
                                                    <h4>{messageLastPage}</h4>
                                                </CCol>
                                            </CRow>
                                        </CCardBody>
                                    </CCard>
                                </CContainer >
                            }
                        </div>
                    ) :
                    (
                        <div
                            style={{ backgroundColor: '#41826e', fontFamily: 'kanit', paddingTop: 20 }}
                            className="min-vh-100 d-flex flex-row align-items-center" >
                            <CContainer>
                                <CRow className="justify-content-center">
                                    <CCol style={{ textAlign: 'center' }}>
                                        <CSpinner color="light" size="xxl" />
                                    </CCol>
                                </CRow>
                            </CContainer>
                        </div>
                    )
                }
            </>
        )
    }
}

export default AdminRegister
