import React, { Component } from "react";
import FixRequiredSelect from "./FixRequiredSelect"
import BaseSelect from "react-select";
import { FixedSizeList as List } from "react-window";

const colourStyles = {
    singleValue: (styles) => ({ ...styles, padding: 5 }),
};
const height = 35;
//Fix  React-select is slow when you have more than 1000 items
class MenuList extends Component {
    render() {
        const { options, children, maxHeight, getValue } = this.props;
        const [value] = getValue();
        const initialOffset = options.indexOf(value) * height;

        return (
            <List
                height={options.length <= 8 ? height * options.length : maxHeight}
                itemCount={children.length}
                itemSize={height}
                initialScrollOffset={initialOffset}
            >
                {({ index, style }) => <div style={style}>{children[index]}</div>}
            </List>
        );
    }
}
//base on react select component 
//other fetures pls read https://react-select.com/
const Select = props => {
    var options = props.options
    var defaultSingleValue = props.defaultSingleValue
    var defaultMultiValue = props.defaultMultiValue
    var defaultValue;
    function setDefaultSingleValue(value) {
        if (!value) return null;
        else {
            return options.findIndex(
                (data) => data.value == value
            );
        }
    }

    function findIndexList(list, value) {
        return list.findIndex(
            (data) => data.value == value
        );
    }
    function setDefaultSingleValue(value) {
        if (!value) return null;
        else {
            return options[findIndexList(options, value)]
        }
    }


    function setDefaultMultiValue(defaultMultiValue) {
        if (!defaultMultiValue) {
            return []
        } else {
            try {
                defaultValue = []
                defaultMultiValue.map(data => {
                    defaultValue.push(
                        options[findIndexList(options, data)]
                    )
                })
                return defaultValue
            }
            catch (e) {
                return null
            }
        }
    }

    if (defaultSingleValue && options.length > 0) {
        defaultValue = setDefaultSingleValue(defaultSingleValue)

    } else if (defaultMultiValue && options.length > 0) {
        defaultValue = setDefaultMultiValue(defaultMultiValue)
    }

    return (
        <>
           
            <FixRequiredSelect
                {...props}
                value={defaultValue != undefined ? defaultValue : ""}
                styles={colourStyles}
                SelectComponent={BaseSelect}
                options={props.options || options}
                components={{ MenuList }}
            />
        </>
    )

}
export default Select
