import React, { useState, lazy } from "react"
import MomentUtils from "@date-io/moment";
import moment from "moment";
import OverwriteMomentBE from "./OverwriteMomentBE"
import { MuiPickersUtilsProvider, DatePicker, TimePicker } from "@material-ui/pickers";
import "moment/locale/th"
import { withStyles } from "@material-ui/core/styles";

const DateInput = (props) => {
    console.log('test')
    var w = window.innerWidth;
    var orientation = w <= 490 ? 'horizon' : 'landscape'
    var dateValue = props.value ? new Date(props.value) : null// new Date('01-01-2022 08:00:00')//moment(props.value) : null
    return (
        <>
            <div className="container-date-input">
                <MuiPickersUtilsProvider utils={OverwriteMomentBE} locale={"th"}>
                    <TimePicker
                        {...props}
                        ampm={false}
                        inputVariant="outlined"
                        inputProps={{
                            style: {
                                padding: "7px 12px"
                            }
                        }}
                        orientation={orientation}
                        value={dateValue}
                    />
                </MuiPickersUtilsProvider>
            </div>
        </>
    )
}

export default DateInput
